import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './index.css'
import { TagsInput } from 'react-tag-input-component'
import { useNavigate, useLocation } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import { ForumHandler } from '../../../../Utils'
import {
  createCollabEntry,
  subscribePost,
} from '../../../../../redux/actions/collabAction'
import { getForumMetadata } from '../../../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../../../context/authProvider'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../../../Helper/ToastObjects'
import 'quill-mention'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MentionHandler from '../../../Utilities/quillSearch'

function AddQuestion() {
  let tokenValue
  let userFetchTimeOut
  let [channel, setChannel] = React.useState('Data Engineering')
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }

  const [authCreds] = useContext(AuthContext)
  const postData = useSelector((state) => state.postData)

  const [emails, setEmails] = useState([])

  const forumMetadata = useSelector((state) => state.forumMetadata)

  function handleChange(e) {
    setChannel(e.target.value)
  }

  const [title, setTitle] = useState('')
  // const [body, setBody] = useState('')
  const [editorContent, setEditorContent] = useState('')
  const [tag, setTag] = useState([])
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const history = useNavigate()
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint

  const location = useLocation()
  const isFromUsers = location.state && location.state.from === 'users'
  const username = location.state ? location.state.userName : ''
  const quillRef = useRef(null)

  const useFocusAndSetRef = (ref) => {
    return useCallback(
      (node) => {
        if (node !== null) {
          ref.current = node
          const quillEditor = node.getEditor()
          const len = quillEditor.getLength()
          quillEditor.setSelection(len, len)
          quillEditor.focus()
        }
      },
      [ref]
    )
  }

  useEffect(() => {
    if (isFromUsers && username) {
      const newContent = `@${username} `
      setEditorContent(newContent)
    }
  }, [isFromUsers, username])

  const setQuillRef = useFocusAndSetRef(quillRef)

  const handleQuill = (value) => {
    setEditorContent(value)
  }
  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)

  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=Q_AND_A_FORUM`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  const handleSubcriptionMulti = async (value, id) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    if (value.length > 0) {
      const body = {
        user_email: value,
        post_id: id,
      }
      dispatch(subscribePost(endpoint, body, false)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
        history('/forum')
      })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      history('/forum')
    }
  }
  useEffect(() => {
    if (postData?.loading == false) {
      let quesData = postData?.postData?.data
      if (emails.length > 0) {
        handleSubcriptionMulti(emails, quesData?.id)
      }
    }
  }, [postData?.postData?.data, emails])
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoadingStatus({ status: true, message: 'Question Adding...' })

    if (title !== '' && editorContent.replace('<p><br></p>', '') !== '' && tag.length !== 0) {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const bodyJSON = {
        title: title,
        description: editorContent,
        tags: tag,
        channel: channel,
        user_email: tokenValue?.preferred_username.toLowerCase(),
        mentioned_emails: mentionVal,
      }

      dispatch(createCollabEntry(endpoint, bodyJSON)).then(() => {
        if (mentionVal.length > 0) {
          setEmails(mentionVal)
        } else {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          history('/forum')
        }
      })
    } else {
      let missingElements = []
      if (title == '') {
        missingElements.push('title')
      }
      if (editorContent.replace('<p><br></p>', '') == '') {
        missingElements.push('body')
      }
      if (tag == '') {
        missingElements.push('tags')
      }
      missingElements.join(', ')
      const errorMsg = 'Please update mandatory fields: ' + missingElements.join(', ');
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast(errorMsg, toastTopRightError)
    }
  }
  return (
    <>
      {loadingStatus.status || forumMetadata?.loading ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="add-question">
          <div className="add-question-container">
            <div className="head-title">
              <h1>Ask a public question</h1>
            </div>
            <div className="question-container">
              <div className="question-options">
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Title
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>
                    <small>
                      Be specific and imagine you’re asking a question to
                      another person
                    </small>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      placeholder="e.g Is there an R function for finding teh index of an element in a vector?"
                    />
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Body
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>

                    <small>
                      Include all the information someone would need to answer
                      your question
                    </small>
                    <ReactQuill
                      style={{ background: '#fff' }}
                      theme="snow"
                      className="react-quill"
                      ref={setQuillRef}
                      defaultValue={editorContent}
                      onChange={handleQuill}
                      modules={module}
                    // formats={allowedFormats}
                    />
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Tags
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>
                    <small>
                      Add up to 5 tags to describe what your question is about
                    </small>

                    <TagsInput
                      value={tag}
                      style={{ background: '#fff' }}
                      onChange={setTag}
                      name="tags"
                      seprators={["Enter", "Tab"]}
                      placeHolder="press enter or tab to add new tag"
                    />

                    {/* <ChipsArray /> */}
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Channel
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Channel Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={channel}
                        onChange={handleChange}
                        style={{ backgroundColor: 'white' }}
                        label="Select Channel Name"
                      >
                        {forumMetadata?.forumMetadata?.channels?.map(
                          (chapter, index) => {
                            //Check if user is a chapter admin or not if the chapter value is Head of Chapters. In this case we do not want to show user this chapter in dropdown
                            const isHeadOfChapters = chapter === 'Head of Chapters';
                            const isAdmin = forumMetadata?.forumMetadata?.channel_admin_emails?.includes(tokenValue.preferred_username.toLowerCase());

                            // Render option conditionally
                            if (isHeadOfChapters && !isAdmin) return null;
                            return (
                              <MenuItem value={chapter} key={index}>
                                {chapter}
                              </MenuItem>
                            )
                          }
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            <button onClick={handleSubmit} className="Addbutton">
              Add your question
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AddQuestion
