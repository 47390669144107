import {
    GET_ANNOUNCEMENT_ERROR,
    GET_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_LOAD,
    ADD_ANNOUNCEMENT_LOAD,
    ADD_ANNOUNCEMENT,
    ADD_ANNOUNCEMENT_ERROR,
    UPDATE_ANNOUNCEMENT_LOAD,
    UPDATE_ANNOUNCEMENT,
    UPDATE_ANNOUNCEMENT_ERROR
  } from '../../constants/types'
  import axios from 'axios'
  import {
    toastTopRightError,
    toastTopRightSuccess,
  } from '../../../components/Helper/ToastObjects'
  import { toast } from 'react-toastify'
  import 'react-toastify/dist/ReactToastify.css'

export const getAllAnnouncements = (endpoint, params) => async (dispatch) => {
    dispatch({
      type: GET_ANNOUNCEMENT_LOAD,
    })
    try {
      await axios
        .get(`${endpoint}/api/announcements/get_announcement`, {
          params: params,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          dispatch({
            type: GET_ANNOUNCEMENT,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_ANNOUNCEMENT_ERROR,
        payload: e.response.data.message,
      })
    }
}

export const AddAnnouncement = (URL, payload) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_ANNOUNCEMENT_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .post(URL, payload, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: ADD_ANNOUNCEMENT,
            payload: res.data,
          })
          toast(
            'Created Announcement successfully!',
            toastTopRightSuccess
          )
        })
    } catch (e) {
      dispatch({
        type: ADD_ANNOUNCEMENT_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
}
export const UpdateAnnouncement = (URL, payload) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_ANNOUNCEMENT_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(URL, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: UPDATE_ANNOUNCEMENT,
          payload: res.data,
        })
        toast(
          'Created Announcement successfully!',
          toastTopRightSuccess
        )
      })
  } catch (e) {
    dispatch({
      type: UPDATE_ANNOUNCEMENT_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
