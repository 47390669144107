import React, { useState } from 'react'
import { Modal, Button, Form, Col } from 'react-bootstrap'
import 'react-quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { TagsInput } from 'react-tag-input-component'
import jwt_decode from 'jwt-decode'
import { editGlossaryTerm } from '../../../redux/actions/glossaryAction'
import { useDispatch } from 'react-redux'

const EditTermModal = ({
  onOpen,
  onClose,
  channels,
  tokenValue,
  existingTermData,
  endpoint,
}) => {
  tokenValue = jwt_decode(tokenValue)
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    term: existingTermData?.term,
    modified_by: tokenValue?.preferred_username,
    definition: existingTermData?.definition,
    role_type: existingTermData?.role_type,
    tags: existingTermData?.tags?.map((tag) => tag.tag_name),
    is_khc_term: existingTermData?.is_khc_term,
  })

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleTagsChange = (tags) => {
    setFormData({
      ...formData,
      tags: tags,
    })
  }

  const handleRichTextChange = (def) => {
    setFormData({
      ...formData,
      definition: def,
    })
  }

  const handleCheckboxChange = (e) => {
    const { name } = e.target
    setFormData({
      ...formData,
      [name]: e.target.checked,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!formData.definition.trim()) {
      alert('Please enter a definition.')
      return
    }
    await dispatch(
      editGlossaryTerm(endpoint, formData, existingTermData.id)
    ).then(() => {})
    onClose()
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]

  return (
    <Modal
      show={onOpen}
      onHide={onClose}
      size="xl"
      className="glossary-modal-body"
    >
      <Modal.Header closeButton>
        <h2 className="modal-title">Edit Term - {existingTermData.term}</h2>
      </Modal.Header>
      <Modal.Body className="glossary-modal-body">
        <Form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>Term Name</Form.Label>
            <Form.Control
              type="text"
              name="term"
              placeholder="Enter term name"
              value={formData.term}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="created_by" className="mb-3">
            <Form.Label>KHC Email</Form.Label>
            <Form.Control
              type="email"
              name="created_by"
              placeholder={tokenValue.preferred_username}
              value={tokenValue.preferred_username}
              onChange={handleInputChange}
              disabled
              required
            />
          </Form.Group>
          <Form.Group as={Col} controlId="definition" className="mb-3">
            <Form.Label>Definition</Form.Label>
            <ReactQuill
              theme="snow"
              style={{ background: '#fff' }}
              value={formData.definition}
              onChange={handleRichTextChange}
              modules={modules}
              formats={formats}
              className="rich-text-editor"
              required
            />
          </Form.Group>
          <Form.Group controlId="formTags" className="mb-3">
            <Form.Label>Tags</Form.Label>
            <TagsInput
              value={formData.tags}
              style={{ background: '#fff' }}
              onChange={handleTagsChange}
              name="tags"
              placeHolder="Press Enter to add a new tag"
            />
          </Form.Group>
          <Form.Group controlId="role_type" className="mb-3">
            <Form.Label>Select a Chapter</Form.Label>
            <Form.Control
              as="select"
              name="role_type"
              value={formData.role_type}
              onChange={handleInputChange}
            >
              {channels?.map((channel, index) => (
                <option key={index} value={channel}>
                  {channel}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="is_khc_term" className="mb-3">
            <Form.Label>Kraft Heinz Term?</Form.Label>
            <Form.Check
              value={formData.is_khc_term}
              style={{ background: '#fff' }}
              onChange={handleCheckboxChange}
              name="is_khc_term"
              type="checkbox"
              label="Yes"
              checked={formData.is_khc_term}
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default EditTermModal
