import React from 'react'
import Sidebar from '../../AppBar/Appbar'
import ViewTerm from './viewTerm'
import LoadingStatus from '../../../Helper/LoadingStatus'
import { RoleHandler } from '../../Utilities/roleCheck'

import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import './index.css'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  color: theme.palette.text.secondary,
  height: '100%',
}))

export function Index() {
  let tokenValue = localStorage?.getItem('token')
  const isAdmin = RoleHandler.checkAdminRole(tokenValue)
  console.log(isAdmin)
  const [loadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [channel] = React.useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="View Glossary Term"
            Index={
              <Item>
                <ViewTerm isAdmin={isAdmin} channel={channel} />
              </Item>
            }
          />
        </>
      )}
    </>
  )
}

export default Index
