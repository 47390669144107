import React, {useState, useContext, useEffect} from "react"
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Sidebar from '../AppBar/Appbar'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import CardsView from '../Utilities/cardView'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'
import LightbulbIcon from '@mui/icons-material/Lightbulb'
import CampaignIcon from '@mui/icons-material/Campaign'
import { getAllAnnouncements } from  '../../../redux/actions/announcementActions/announcementAction'
import { getMyBadges, getRecentBadges, getRecentKudos, getUserKudos } from '../../../redux/actions/badgeAction'
import { getPods } from '../../../redux/actions/podsPageActions/podsAction'
import { getAllIdeas } from '../../../redux/actions/ideasActions/ideasAction'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle'
import CelebrationIcon from '@mui/icons-material/Celebration'
import chapter_overview from '../../../media/chapter_overview.png'
import '../../../App.css'
import './dashboard.css'

export default function KholabDashboard(){
    const history = useNavigate()
    let decodedToken
    let tokenValues = localStorage?.getItem('token')
    if (tokenValues) {
        decodedToken = jwt_decode(tokenValues)
    }
    const handleClick = (path) => {
        history(path)
    }
    const [showChapterOverView, setShowChapterOverView] = useState(false);
    const [showKholabMore, setShowKholabMore] = useState(false);
    const [announcementData, setAnnouncmentData] = useState([]);
    const [recentBadges, setRecentBadges] = useState([]);
    const [recentKudos, setRecentKudos] = useState([]);
    const [kudosCount, setKudosCount] = useState(0);
    const [podCount, setPodCount] = useState(0);
    const [badgeCount, setBadgeCount] = useState(0);
    const [ideaCount, setIdeaCount] = useState(0);
    const [kudoModal, setkudoModal] = useState(false)
    const handleOpenKudoModal = (flag)=>{
        fetchRecentKudos();
        setkudoModal(flag);
    }
    const handleViewMore = (e, page) =>{
        e.stopPropagation();
        if(page == 'forum') {
            setShowKholabMore(true);
        } else {
            setShowChapterOverView(true);
        }
        
    }
    const handleCloseKholabModal = ()=>{
        setShowKholabMore(false);
        setShowChapterOverView(false);
    }
    const numDifferentiation = (val) => {
        if(val >= 10000000) val = (val/10000000).toFixed(2) + 'C';
        else if(val >= 100000) val = (val/100000).toFixed(2) + 'L';
        else if(val >= 1000) val = (val/1000).toFixed(2) + 'K';
        return val;
    }
    const sendChapterMail = (e, distributionMail) => {
        e.stopPropagation();
        window.location.href = `mailto:${distributionMail}`;
    }
    const viewKnowledgeRepository = (e, knowledgeLink) => {
        e.stopPropagation();
        window.open(knowledgeLink, '_blank');

    }
    const ChapterHeadMail = (e, chapterHeadMail) => {
        e.stopPropagation();
        window.location.href = `mailto:${chapterHeadMail}?subject=KHOLAB: Head of Chapter Email`;
    }
    const [authCreds] = useContext(AuthContext)
    const dispatch = useDispatch()
    const myBadges = useSelector((state) => state.myBadges)
    const podsData = useSelector((state) => state.podsData?.podsMenuData)
    const myKudos = useSelector((state) =>  state.userKudos?.userKudos)
    const getAnnouncementData = useSelector((state)=> state.announcementReducer.getAllAnnouncements)
    const getAllIdeasData = useSelector( (state) => state.getAllIdeasReducer.getAllIdeas)
    const getRecentKudosData = useSelector( (state)=>  state.userKudos?.recentKudos)
    const endpoint = authCreds.restEndpoint
    const fetchAnnouncements = () => {
        const params = {
            page_size: 3,
            page_number: 1,
            table_name: "DE_ANNOUNCEMENTS",
            show_deleted: false,
            show_audit_table: false,
            include_deleted: false,
            // sort: JSON.stringify([{"field":"KH_UID","direction":"desc"}])

        }
        dispatch(getAllAnnouncements(endpoint, params));        
    }
    const fetchBadgeCount = () => {
        const badgeParams = {
            page_size: 1,
            page_number: 1
        }
        dispatch(getMyBadges(endpoint, badgeParams))
    }
    const fetchRecentBadges = () => {
        const badgeParams = {
            page_number: 1,
            badge_limit: 6
        }
        dispatch(getRecentBadges(endpoint, badgeParams))
    }
    const fetchRecentKudos = () => {
        const kudosParams = {
            page_number: 1,
            kudos_limit: 5
        }
        dispatch(getRecentKudos(endpoint, kudosParams))

    }
    const fetchPodCount = () => {
        dispatch(
            getPods(endpoint, decodedToken.preferred_username.toLowerCase())
          )
    }
    const fetchKudosCount = () => {
        dispatch(getUserKudos(endpoint, decodedToken.preferred_username.toLowerCase()))
    }
    const fetchIdeasCount = () => {
        const url =`${endpoint}/api/ideas/my_ideas`
        const ideaParams = {page_size: 1, page_number: 1};
        dispatch(getAllIdeas(url, ideaParams));
    }
    const [initLoad, setInitLoad] = useState(false)
    useEffect(()=>{
        fetchRecentKudos();
    }, [kudoModal])
    useEffect(() => {
      if (initLoad === true) {
        return
      }
      setInitLoad(true)
      fetchAnnouncements()
      fetchBadgeCount();
      fetchPodCount();
      fetchKudosCount();
      fetchIdeasCount();
      fetchRecentBadges();
      fetchRecentKudos();
    }, [])
    useEffect(()=>{
        setAnnouncmentData(getAnnouncementData?.data);
        if(podsData && podsData.data && podsData.data.my_pods) {
            setPodCount(podsData.data.my_pods.length)
        }
        if(myBadges && myBadges.myBadges && myBadges.myBadges.total_results) setBadgeCount(myBadges.myBadges.total_results);
        if(myKudos) setKudosCount(myKudos.length)
        if(getAllIdeasData && getAllIdeasData.total_results) setIdeaCount(getAllIdeasData?.total_results)
        if(myBadges && myBadges.recentBadges) setRecentBadges(myBadges.recentBadges.data)

        if(getRecentKudosData &&  getRecentKudosData.data) setRecentKudos(getRecentKudosData.data)
        
    }, [getAnnouncementData, podsData, myBadges, myKudos, getAllIdeasData, getRecentKudosData])
    const chapterHeads = {
        "message": "Ok",
        "total_results": 12,
        "data": [
            {
                "user_email": "maria.vasu@kraftheinz.com",
                "user_name": "maria.vasu",
                "role_type": "Agile",
                "link": "",
                "link_desc": "",
                "distribution_list": "dr-globalagilecoe@kraftheinz.com",
                "description": ""
            },
            {
                "user_email": "joseph.mcnamara@kraftheinz.com",
                "user_name": "joseph.mcnamara",
                "role_type": "Architecture",
                "link": "https://heinz365.sharepoint.com/sites/GlobalIT_EA",
                "link_desc": "",
                "distribution_list": "",
                "description": "Responsible for aligning business strategy to technology strategy via capability analysis and roadmaps. Drives strategic IT transformation programs (e.g. Capable, Lean & Health), guides system architecture designs, and supports handover to engineering teams for delivery.  Leads architecture governance which sets architecture standards/guideline, provides project & product guidance, and oversees execution of enterprise IT strategy."
            },
            {
                "user_email": "jaques.castello@kraftheinz.com",
                "user_name": "jaques.castello",
                "role_type": "BI Engineering",
                "link": "https://heinz365.sharepoint.com/:u:/r/sites/GlobalBusinessIntelligence/SitePages/ProjectHome.aspx?csf=1&web=1&e=gB1FUc",
                "link_desc": "Need to request access",
                "distribution_list": "BI.CHAPTER@kraftheinz.com",
                "description": "Responsible for delivering critical business data in accessible formats such as reports, dashboards, and visualizations. BI teams are responsible for the ongoing analysis of business data to support decision-making across the organization."
            },
            {
                "user_email": "ashish.agrawal@kraftheinz.com",
                "user_name": "ashish.agrawal",
                "role_type": "Cloud Engineering",
                "link": "https://kraftheinz.atlassian.net/wiki/spaces/CCOE/pages/2201255941",
                "link_desc": "Jira Access Required",
                "distribution_list": "CloudCOE@kraftheinz.com",
                "description": "Responsible for providing landing zones, advanced ecosystem of tools, platforms, knowledge resources, and Infrastructure as Code framework, to empower Digital and IT teams (across horizons) to create cost-efficient and sustainable cloud solutions."
            },
            {
                "user_email": "bryan.chavez@kraftheinz.com",
                "user_name": "bryan.chavez",
                "role_type": "Data Engineering",
                "link": "https://deweb.apps.kraftheinz.com/home",
                "link_desc": "",
                "distribution_list": "sgkhc_data_engineering@kraftfoods.com",
                "description": "Responsible for designing, building, and maintaining systems and integrations for collecting, storing, and analyzing data at scale. It's the vital infrastructure that allows businesses to make data-driven decisions, develop new products and services"
            },
            {
                "user_email": "diogo.cassimiro@kraftheinz.com",
                "user_name": "diogo.cassimiro",
                "role_type": "Data Stewardship",
                "link": "",
                "link_desc": "",
                "distribution_list": "",
                "description": ""
            },
            {
                "user_email": "andrew.lindsay@kraftheinz.com",
                "user_name": "andrew.lindsay",
                "role_type": "Enterprise Design",
                "link": "https://heinz365.sharepoint.com/sites/KHEnterpriseDesign",
                "link_desc": "",
                "distribution_list": "ent.design@kraftheinz.com",
                "description": "Responsible for design thinking, user experience (UX) design, and engineering principles to create meaningful and holistic experiences for users. They go beyond the traditional UX designer by focusing on the entire journey of the user, including physical and digital touchpoints, emotions, and social interactions."
            },
            {
                "user_email": "mir.ali11@kraftheinz.com",
                "user_name": "mir.ali11",
                "role_type": "Full-Stack Software Engineering",
                "link": "https://heinz365.sharepoint.com/sites/KHDigitalEngineering/SitePages/Team%20Page%202.aspx",
                "link_desc": "",
                "distribution_list": "DL_KHC_FullStack_ENGINEERING@kraftheinz.com",
                "description": "Responsible for the back end and front-end design, documentation, development, modification, testing, installation, implementation, maintenance of new or existing applications."
            },
            {
                "user_email": "jorge.balestra@kraftheinz.com",
                "user_name": "jorge.balestra",
                "role_type": "ML Engineering",
                "link": "https://heinz365.sharepoint.com/sites/KHCMLOps258",
                "link_desc": "",
                "distribution_list": "DL_KHC_MLOPS_ENGINEERING@kraftheinz.com",
                "description": "Responsible for building and deploying machine learning (ML) models into production. ML engineers are responsible for the entire ML pipeline, from data collection and preparation to model training, deployment, and monitoring. They are also responsible for maintaining the successful execution of ML pipelines, scaling and optimizing them when required."
            },
            {
                "user_email": "joy.soda@kraftheinz.com",
                "user_name": "joy.soda",
                "role_type": "Service Operations",
                "link": "https://teams.microsoft.com/l/channel/19%3AyCWAQLM9FbJxMWWicAsiLeJXSfG2Yavnc7Dc-TH3ILI1%40thread.tacv2/General?groupId=4471aa4d-048e-4887-8ea5-389eb45e610b&tenantId=",
                "link_desc": "Need to Request Access",
                "distribution_list": "dlkhcoperationschapterleads@kraftheinz.com",
                "description": ""
            },
            {
                "user_email": "patrick.nestor@kraftheinz.com",
                "user_name": "patrick.nestor",
                "role_type": "Advanced Analytics",
                "link": "https://heinz365.sharepoint.com/:fl:/g/contentstorage/CSP_cd64517f-e2c8-4ce7-94b9-49d945f472bb/EaJEG9tCpatEjGScmgzS-jIBMcWB7I9UN12hsy1OeMOHSA?e=8N1Z5s&nav=cz0lMkZjb250ZW50c3RvcmFnZSUyRkNTUF9jZDY0NTE3Zi1lMmM4LTRjZTctOTRiOS00OWQ5NDVmNDcyYmImZD1iJTIxZjFGa3pjamk1MHlVdVVuWlJmUnl1OHRuOEtGaVE0VkVvaERlRGowRXhrTmJXRko1dllTU1Jhc1NuaWJ5WWIwWCZmPTAxTFpPSkRUNUNJUU41V1FWRlZOQ0lZWkU0VElHTkY2UlMmYz0lMkYmYT1Mb29wQXBwJnA9JTQwZmx1aWR4JTJGbG9vcC1wYWdlLWNvbnRhaW5lciZ4PSU3QiUyMnclMjIlM0ElMjJUMFJUVUh4b1pXbHVlak0yTlM1emFHRnlaWEJ2YVc1MExtTnZiWHhpSVdZeFJtdDZZMnBwTlRCNVZYVlZibHBTWmxKNWRUaDBiamhMUm1sUk5GWkZiMmhFWlVScU1FVjRhMDVpVjBaS05YWlpVMU5TWVhOVGJtbGllVmxpTUZoOE1ERk1XazlLUkZRelJrcEhXbGxJU0U1TlQwWkVXVU5HV1VoWlZ6SkJUakpJVXclM0QlM0QlMjIlMkMlMjJpJTIyJTNBJTIyYmJmYTBkYjQtMDk0Ny00NTA2LWI4OGMtY2ZlNWY3NmU5MTlkJTIyJTdE",
                "link_desc": "Loop Page",
                "distribution_list": "dl_khc_global_adv_analytics@kraftheinz.com",
                "description": "Advanced Analytics chapter is comprised of group of employees who specializes in designing & building analytics, machine learning and AI solutions that support business partners around KHC in driving business value through data-driven decisions."
            },
            {
                "user_email": "gyansagar.sikharam@kraftheinz.com",
                "user_name": "gyansagar.sikharam",
                "role_type": "Data Architecture",
                "link": "",
                "link_desc": "",
                "distribution_list": "",
                "description": ""
            }
        ],
        "num_pages": 1,
        "page_size": 12,
        "page_number": 1
    };
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          fontSize: '12px',
          whiteSpace: 'break-spaces',
        },
      }))

    return (
        <Sidebar
            Page="dashboard"
            kudoModal={kudoModal}
            setkudoModal={setkudoModal}
            Index={
                <div>
                    {announcementData &&  announcementData.length > 0 ?
                    <Row className="dashboard-section-type-1">
                        <Col xs={12} md={12} className="badges-container announcements">
                            <div className="kholab-dashboard-heading"><CampaignIcon /> Announcements!</div>
                            <div style={{padding: '1em 1em 0 1em'}}>
                                <ul>
                                    {announcementData.map(item=> <li key={item.KH_UID}>{item.ANNOUNCEMENT}</li>) }
                                </ul>
                            </div>
                        </Col>
                    </Row>: ''}
                    <Row className="dashboard-section-type-1">
                        <Col xs={6} md={3}  style={{paddingLeft: 0}}>
                            <div className="summary-tile">
                                <span className="summary-tile-count">
                                    {numDifferentiation(podCount)}
                                </span>
                                <span className="summary-tile-desc">
                                    <SupervisedUserCircleIcon className="dashboard-metrics-icon" />
                                    My PODs
                                </span>
                            </div>
                        </Col>
                        <Col xs={6} md={3} >
                            <div className="summary-tile">
                                <span className="summary-tile-count">
                                    {numDifferentiation(ideaCount)}
                                </span>
                                <span className="summary-tile-desc">
                                    <LightbulbIcon className="dashboard-metrics-icon"/> My Ideas
                                </span>
                            </div>
                        </Col>
                        <Col xs={6} md={3}>
                            <div className="summary-tile">
                                <span className="summary-tile-count">
                                    {numDifferentiation(badgeCount)}
                                </span>
                                <span className="summary-tile-desc">
                                    <MilitaryTechIcon className="dashboard-metrics-icon" />
                                    My Badges
                                </span>
                            </div>
                        </Col>
                        <Col xs={6} md={3} style={{paddingRight: 0}}>
                            <div className="summary-tile">
                                <span className="summary-tile-count">
                                    {numDifferentiation(kudosCount)}
                                </span>
                                <span className="summary-tile-desc">
                                    <CelebrationIcon className="dashboard-metrics-icon" /> My Kudos
                                </span>
                            </div>
                        </Col>
                    </Row>                    
                    <Row className="dashboard-section-type-2">
                        <Col xs={12} md={8} style={{paddingLeft: 0}}>
                            <CardsView
                                title_content={
                                    <span className="kholab-dashboard-heading">
                                        What is KHOLAB?
                                    </span>
                                }
                                border_status_class='border-dashboard'
                                action_content={
                                    <div className="dashboard-flex dashboard-flex-col">
                                        <p className="chapter-summary" style={{minHeight: 'initial'}}>
                                            Kraft Heinz Chapter Collaboration Tool - a comprehensive platform designed to enhance transparency, communication, and coordination among all Chapters within the organization. This tool enables members to collaborate, share knowledge, and acknowledge each other while also progressing Chapter maturity and Chapter member growth. For chapter leadership, it provides a centralized view for managing PODs, monitoring growth and performance, and facilitating strategy and decision-making around the Chapter structure and initiatives.
                                        </p>
                                        <span className="kholab-dashboard-heading" style={{borderBottom: "1px solid #e9ecef"}}>
                                            Sections
                                        </span>
                                        <div className="dashboard-flex dashboard-flex-col dashboard_gap_0_5">
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Q&amp;A Forum</span>
                                                <span style={{flexBasis: '85%'}}>A quick way to search answers and ask questions with respect to a specific area (some Chapter / some non-Chapter).  This allows the knowledge to be long-lived and visible as opposed to getting lost or archived via email or Microsoft Teams.  This will help prevent the same questions being asked especially for new hires and junior resources.  As the amount and quality of knowledge content increases, a future integration will include automated recommended answers to questions via an LLM (PENDING). </span>
                                            </div>
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Badge</span>
                                                <span style={{flexBasis: '85%'}}>A lense on Chapter members and their competencies identified as critical by the respective Chapter Head and C4E (badges can only be created by Chapter leadership). This view will help Chapter members to identify potential mentors or experts for a given area or help project teams identify individuals suited for specific project deliverables and requirements.  Unlike other certifications tools, a Chapter member may request a badge by submitting evidence that shows practical application of the knowledge as opposed to only academic understanding.  A general guideline around the types of badges are: BRONZE = developing skills, SILVER = individual expert, GOLD = demonstrated sharing/teaching of skills.  The summary view provides leadership a holistic view on chapter member maturity (internal group and external vendor alignment to Chapter competencies).  In addition, clicking on a Chapter member&lsquo;s row, shows their profile which includes specific badge details (as well as kudos).</span>
                                            </div>
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>PODs</span>
                                                <span style={{flexBasis: '85%'}}>A central repository of POD (product-oriented delivery) teams to identify different Chapter members working together to deliver products.  This will give full visibility (all PODs, not just a specific POD) on team member composition and maturity (badges/kudos), quality of the products being delivered (PENDING), as well as product resource consumption (PENDING).  The individual POD view also provides an easy way to contact a specific POD for product-related questions or issues.  This information will be kept up-to-date through a recurring certification process (PENDING).  The product owners and stakeholders will also be used to drive external Chapter NPS surveys within Kholab (PENDING).</span>
                                            </div>
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Ideas</span>
                                                <span style={{flexBasis: '85%'}}>A centralized queue of Chapter ideas that are visible for discourse and voting for the Chapter and the organization. This will help leadership get a pulse on how to improve capabilities, address pain points, and bring new innovations to the Chapter - prioritized on need or interest (upvoting).  Submitted ideas should be more focused on broad changes that multiple members or PODs can benefit from and less focused on POD/Product specific requirements (maintained separately in JIRA/ADO backlogs).</span>
                                            </div>
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Glossary</span>
                                                <span style={{flexBasis: '85%'}}>A community-enriched glossary where anyone can submit and search terms that are relevant to a specific area (some Chapter / some non-Chapter).  This will help new hires, junior resources, and others to get acclimated to jargon, acronyms, and terms important to a Chapter or to the business.  Kraft Heinz specific terms will be highlighted and be filterable (PENDING).</span>
                                            </div>
                                            <div className="dashboard-flex sections-card">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Send Kudos</span>
                                                <span style={{flexBasis: '85%'}}>An easy mechanism to send an appreciation to any colleague (not just full-time employees but also contractors and consultants).  This information will be long-lived and centrally available in the user profile as well as summarized in the Badges/PODs views.  A future integration will incorporate Celebrations (limited to only FTEs) data (PENDING).</span>
                                            </div>
                                            <div className="dashboard-flex">
                                                <span className="dashboard_font_weight_600" style={{flexBasis: '15%'}}>Job Postings</span>
                                                <span style={{flexBasis: '85%'}}>A listing of internal job openings filtered for each Chapter where Kraft Heinz employees can be redirected to apply in Workday or to contact a hiring manager directly.  This will help promote internal hiring and provide visibility to new opportunities within each Chapter.</span>
                                            </div>
                                        </div>
                                        <div className="kholab-dashboard-actions">
                                            <BootstrapTooltip title="Click to View more">
                                                <Button
                                                size="small"
                                                className="idea-button"
                                                onClick={(e) => handleViewMore(e, 'kholab')}
                                                >
                                                View More
                                                </Button>
                                            </BootstrapTooltip>
                                        </div>
                                    </div>
                                }
                            />
                            <Modal size="xl" show={showKholabMore} className=" kholab-dashboard-modal" onHide={handleCloseKholabModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>About KHOLAB</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modalBody">
                                    <div className="dashboard-flex">
                                        <div className="first-part">
                                            <h2>Who are the users?</h2>
                                        </div>
                                        <div className="second-part">
                                            <ul>
                                                <li>
                                                    <strong>Chapter Heads & leadership:</strong> This perspective is more in relation for Chapter maturity, growth and performance
                                                </li>
                                                <li>
                                                    <strong>Product owners:</strong> This perspective is more in direction on POD level as to how good there team is performing, how good are the members in their Chapters, asset qualities build by team, etc
                                                </li>
                                                <li>
                                                    <strong>Builders:</strong> This is more in perspective of developers. How well are they building objects, their exceptions, their badges for Chapter contributions, QnA Forum for checking questions related to framework and workings of certain Chapter.
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Modal.Footer style={{ gap: '10px' }}>
                                        <Button variant="danger" type="submit" className="addModalBtn" onClick={handleCloseKholabModal} style={{ color: '#dc3545', border: '1px solid #dc3545' }}>
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Modal>
                            <CardsView
                                title_content={
                                    <span className="kholab-dashboard-heading">
                                        What is a Chapter?
                                    </span>
                                }
                                border_status_class='border-dashboard'
                                action_content={
                                    <div className="dashboard-flex dashboard-flex-col">
                                        <p className="chapter-summary">
                                        A Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, & guidance on how to perform at their peak. Chapters enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee&rsquo;s role.
                                        </p>
                                        <div className="kholab-dashboard-actions">
                                            <BootstrapTooltip title="Click to View Chapter Overview">
                                                <Button
                                                size="small"
                                                className="idea-button"
                                                onClick={(e) => handleViewMore(e,'chapter')}
                                                >
                                                View More
                                                </Button>
                                            </BootstrapTooltip>
                                        </div>
                                    </div>
                                }
                            />
                            <Modal size="xl" show={showChapterOverView} className=" kholab-dashboard-modal" onHide={handleCloseKholabModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Chapter Overview</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="modalBody">
                                    <div className="dashboard-flex">
                                        <div className="first-part">
                                            <h2>What is a Chapter?</h2>
                                        </div>
                                        <div className="second-part">
                                            <p>A Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, & guidance on how to perform at their peak. Chapters enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee’s role.</p>
                                            <p>Below is a sample of KHC Chapters in practice:</p>
                                            <img data-testid="media-image" draggable="false" alt="image-20240729-193242.png" src={chapter_overview} className="dashboard-modal-image"></img>
                                        </div>
                                    </div>
                                    <div className="dashboard-flex">
                                        <div className="first-part">
                                            <h2>Why Chapters ?</h2>
                                        </div>
                                        <div className="second-part">
                                            <ul>
                                                <li>
                                                    Siloed teams developing siloed products w/ less alignment to enterprise / platform strategy
                                                </li>
                                                <li>
                                                    Macro technical debt - inconsistencies, inefficiencies, duplications across solutions
                                                </li>
                                                <li>
                                                    Imbalance between delivery and continuous improvement/operations for a function
                                                </li>
                                                <li>
                                                    Lack of strategy (tool/people) for how a specific type of work/function will be done across the enterprise
                                                </li>
                                                <li>
                                                    Lack of established best-practices, guard-rails, and standards
                                                </li>
                                                <li>
                                                    Disconnect between platform capabilities and what the users need or want
                                                </li>
                                                <li>
                                                    Lack of governance of the quality of external / vendor resources
                                                </li>
                                                <li>
                                                    Lack of development path to achieve mastery of given skill / domain
                                                </li>
                                                <li>
                                                    Lack of progression, collaboration, and community
                                                    <strong>&ldquo;Scale up with Agile but do it efficiently and responsibly with Chapters&rdquo;</strong>
                                                    <strong>&ldquo;Fusion PODs brings focus to the WHAT/WHY, Chapters bring focus on the HOW&rdquo;</strong>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="dashboard-flex">
                                        <div className="first-part">
                                            <h2>KHC Chapter Model Definitions</h2>
                                        </div>
                                        <div className="second-part">
                                            <h3>Chapter </h3>
                                            <p>Chapter is a cross-cutting global network of specialist employees, sharing a common job function. Chapters ensure that all members have access to frameworks / tools / platforms, professional development, &amp; guidance on how to perform at their peak. Chapter’s enable the high-quality deliverables and outcomes of Chapter members. Membership of Chapters is mandatory where that specialism is the core of an employee’s role.</p>
                                            <p><em>Chapter members may or may not report to the Head of the Chapter.</em></p>
                                            <h3 >Center for Enablement (C4E)</h3>
                                            <p>Center for Enablement (C4E) (optional depending on total number of Chapter members) is focused on enablement and tasked with building frameworks; productizing, publishing &amp; harvesting reusable assets / tools; creating guard rails; creating training material and knowledge repositories; and establishing best practices / standards for the Chapter. C4E Members are highly-experienced senior professionals that support multiple services, projects, platforms, &amp; federated products in an execution and/or advisory capacity to the Fusion PODs / Organization Units / and Communities of Interest.</p>
                                            <p><em>The C4E members report directly to the Head of the Chapter.</em></p>
                                            <h3>Fusion POD</h3>
                                            <p>Fusion POD is a multi-disciplinary team combining technical resources from multiple Chapters to build and operate digital products. Membership of PODs is not static and adjusts to the needs of the Product throughout its life. Product Owners must work together with technical POD members to assign work and provide day-to-day management of the project/program.</p>
                                            <p><em>Pods can be made up of members from multiple Chapters and can be part of an Organization Unit or directly aligned to a specific engagement.</em></p>
                                            <h3>Organization Unit (OU)</h3>
                                            <p>Organization Unit (OU) is a group containing multiple PODs who coordinate between each other under the same administration. The organization unit leadership will be responsible for setting the vision and direction for that organization and encouraging collaboration across PODs / Chapter members within those PODs.</p>
                                            <p><em>The organization unit is made up of PODs with members from different chapters.</em></p>
                                            <h3>Community of Interest / Practice (CoI)</h3>
                                            <p>Communities of Interest (a.k.a. Communities of Practice) is a group of people with a special interest in an area. They may be linked to Chapters but is not essential or required. Membership of communities of interest is optional and open to all.</p>
                                            <p><em>Communities of interest will typically be led by a volunteer coordinator from a relevant Chapter (not a formal role).</em></p>
                                        </div>
                                    </div>
                                    <div className="dashboard-flex">
                                        <div className="first-part">
                                            <h2>When does a Chapter make sense?</h2>
                                        </div>
                                        <div className="second-part">
                                            <h3>Job Function Employee Density</h3>
                                            <ul>
                                                <li>Employees less than 5 - No</li>
                                                <li>Employees 5 to 10 - Maybe</li>
                                                <li>Employees greater than 10 - Yes</li>
                                            </ul>
                                            <h3>Geographical Coverage</h3>
                                            <ul>
                                                <li>Employees limited to a single zone - Maybe</li>
                                                <li>Employees global presence - Yes</li>
                                            </ul>
                                            <h3>Job Complexity</h3>
                                            <ul>
                                                <li>Highly-specialized job function - Yes</li>
                                                <li>Complex role requiring continuous development across multiple areas (depth or breadth) - Yes</li>
                                                <li>Would benefit from centralized governance and standards - Yes</li>
                                                <li>Would benefit from centralized enablement of capabilities, process, and platforms - Yes</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Modal.Footer style={{ gap: '10px' }}>
                                        <Button variant="danger" type="submit" className="addModalBtn" onClick={handleCloseKholabModal} style={{ color: '#dc3545', border: '1px solid #dc3545' }} >
                                            Close
                                        </Button>
                                    </Modal.Footer>
                                </Modal.Body>
                            </Modal>
                        </Col>
                        <Col xs={12} md={4} className="dashboard-flex dashboard-flex-col">
                            <div className="badges-container">
                                <div className="kholab-dashboard-heading"><MilitaryTechIcon /> New badges earned!</div>
                                <div className="dashboard-flex dashboard-flex-col dashboard-badge-content">
                                    {(recentBadges && recentBadges.length > 0)  ? (recentBadges.map(item=>{

                                        const badgeColor = item.badge_level_name == 'Gold' ? `gold_badge_color` : item.badge_level_name == 'Silver' ? `silver_badge_color`: `bronze_badge_color`;

                                        const badgeContainer = item.badge_level_name == 'Gold' ? `gold_badge_container` : item.badge_level_name == 'Silver' ? `silver_badge_container`: `bronze_badge_container`;
                                    return (
                                        <>
                                        <BootstrapTooltip title={item.badge_level_name+' badge earned for ' + item.badge_name}>
                                        <div  className={`dashboard-flex dashboard_gap_0_5 recent-badge-container ${badgeContainer}`} key={item.id}>
                                        <span><MilitaryTechIcon className={`icons ${badgeColor}`} /></span>
                                        <div className="badge_container_desc">
                                            <span className="dashboard-transform-capitalize">{item.badge_name ? item.badge_name : 'N/A'}</span>
                                            <span class="idea_category_badge badge-primary badge_container_desc_role_type">{item.role_type}</span>
                                        </div>
                                        
                                    </div>

                                        </BootstrapTooltip>
                                    
                                    <span className="dashboard_badge_user">
                                    - {item.user_email ? item.user_email.split('@')[0] : 'N/A'} {item?.reviewed_on && (<span className="badge_timestamp"> ({ new Date(item?.reviewed_on).toISOString().split('T')[0]})</span>
                                     )}
                                     </span>
                                    
                                    </>
                                    )
                                    })) : ''}
                                </div>
                                <div className="kholab-dashboard-actions">
                                    <BootstrapTooltip title="Click to View Badges">
                                        <Button
                                        size="small"
                                        className="idea-button"
                                        onClick={() => handleClick('/badge')}
                                        >
                                        View More
                                        </Button>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                            <div className="badges-container recent-kudos">
                                <div className="kholab-dashboard-heading dashboard-flex dashboard_gap_0_5"><CelebrationIcon /> Recent Kudos!</div>
                                <div className="dashboard-flex dashboard-flex-col dashboard-kudos-content">
                                    {recentKudos.map((item) => {
                                        return (
                                            <div className="dashboard-flex dashboard-flex-col" key={item.id}>
                                                <span className="dashboard-transform-capitalize dashboard_font_weight_600">{item.receiver_email ? item.receiver_email.split('@')[0] : 'N/A'}</span>
                                                <span>
                                                {item.message && item.message.length > 120 ? (
                                                    <BootstrapTooltip title={item.message}>
                                                        &ldquo;`{item.message.substring(0, 120)}...&rdquo;
                                                    </BootstrapTooltip>
                                                ):
                                                (<>&ldquo;{item.message}&rdquo;</>)
                                                }
                                                </span>
                                                <span className="dashboard-flex dashboard-transform-capitalize dashboard-justify-end">- {item.sender_email ? item.sender_email.split('@')[0] : 'N/A'}{item?.submitted_on && (<span className="badge_timestamp"> ({ item?.submitted_on})</span>)}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="kholab-dashboard-actions">
                                    <BootstrapTooltip title="Click to Send Kudos">
                                        <Button
                                        size="small"
                                        className="idea-button"
                                        onClick={()=>handleOpenKudoModal(true)}
                                        >
                                        Send Kudos
                                        </Button>
                                    </BootstrapTooltip>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="dashboard-section-type-2">
                        <Col xs={12} md={12} className="kholab-home-card" style={{paddingLeft: 0}}>
                            <CardsView 
                                title_content={
                                    <div className="dashboard-chapter-head-heading dashboard-flex dashboard_gap_0_5">
                                        <PeopleAltIcon /> Chapters at KHC
                                    </div>
                                }
                                border_status_class='border-dashboard'
                                action_content={
                                    <div>
                                        <Table bordered hover>
                                            <thead>
                                                <tr style={{ fontWeight: '500', fontSize: '1rem', lineHeight: '1.125rem', verticalAlign: 'middle', color: '#78829d'}}>
                                                    <th>Chapter</th>
                                                    <th>Head of Chapter</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{fontSize: '.875rem', lineHeight: '1.25rem'}}>
                                                {chapterHeads.data.map((item)=>{
                                                    
                                                    return(
                                                        <tr key={item.user_email}>
                                                            <td>
                                                                {item.description ? (<BootstrapTooltip title={item.description} style={{textDecoration: 'underline', color: 'cornflowerblue'}}>
                                                                    {item.role_type ? item.role_type: 'N/A'}
                                                                </BootstrapTooltip>): (item.role_type ? item.role_type: 'N/A')}
                                                                
                                                            </td>
                                                            <td>
                                                                <BootstrapTooltip title={`Click to mail Chapter Head`}>
                                                                    <a href="#" onClick={(e)=>ChapterHeadMail(e, item.user_email)} style={{textDecoration: 'underline', color: 'cornflowerblue'}}>
                                                                        {item.user_email}
                                                                        </a>
                                                                </BootstrapTooltip>
                                                                        
                                                            </td>
                                                            <td className="dashboard-flex dashboard_gap_0_5">
                                                                {item.distribution_list ? (
                                                                    <BootstrapTooltip title={`Click to mail ${item.distribution_list}`}>
                                                                    <Button variant="contained" className="apply_job_button" onClick={(e) => sendChapterMail(e,item.distribution_list)} size="small" >EMAIL CHAPTER</Button>
                                                                    </BootstrapTooltip>
                                                                ):''}
                                                                {item.link ? (
                                                                    <BootstrapTooltip title={`Click to Go to Chapter Knowledge Repository about ${item.role_type} ${item.link_desc ? '('+item.link_desc+')' : ''}`}>
                                                                    <Button variant="contained" className="apply_job_button" onClick={(e) => viewKnowledgeRepository(e,item.link)}   size="medium" >VIEW CHAPTER DOCS</Button>
                                                                    </BootstrapTooltip>
                                                                ):''}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                
                                            </tbody>
                                        </Table>
                                    </div>
                                }
                            />

                        </Col>
                    </Row>
                </div>
            }
        />
    )

}
