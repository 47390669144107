import React, {useState, useMemo, useContext, useEffect} from 'react'
import Sidebar from '../AppBar/Appbar'
import '../RefData/RefTable.css'
import { useTable, usePagination, useRowSelect, useFilters, useGlobalFilter, useSortBy} from 'react-table'
import { Table, Col, Row, Button, Container, Form, InputGroup } from 'react-bootstrap'
import { TableHandler } from '../../Utils'
import { getAllAnnouncements, UpdateAnnouncement, AddAnnouncement  } from  '../../../redux/actions/announcementActions/announcementAction'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../context/authProvider'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Modal from 'react-bootstrap/Modal'
import LoadingStatus from '../../Helper/LoadingStatus'

export default function Announcements() {
    const [loadingStatus, setLoadingStatus] = useState({
        status: false,
        message: 'Fetching table records...',
    })
    const BootstrapTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
          color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: theme.palette.common.black,
          fontSize: '12px',
          whiteSpace: 'break-spaces',
        },
    }))
    const [authCreds] = useContext(AuthContext)
    /* eslint-disable no-unused-vars */
    const [editRows, setEditRows] = useState([])
    const [totalRows, setTotalRows] = useState(0)
    const [showModal, setshowModal] = useState(false)
    const [announcementInput, setAnnouncementInput] = useState('');
    const [UID, setUID] = useState('');
    const [chapterInput, setChapterInput] = useState('');
    const [tableColumns, setTableColumns] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [tableData, setTableData] = useState([])
    const [channel, setChannel] = useState(localStorage.getItem('channel'))
    const [columnSort, setColumnSortValue] = useState([])
    const [localPageIndex, setLocalPageIndex] = useState(0)
    const [columnFilters, setColumnFilters] = useState([])
    const dispatch = useDispatch()
    const getAnnouncementData = useSelector((state) => state.announcementReducer.getAllAnnouncements)
    const endpoint = authCreds.restEndpoint
    const table_name = 'DE_ANNOUNCEMENTS'
    const columnMetadata = {
        DE_ANNOUNCEMENTS: {
        fields: [
            {
            name: 'ANNOUNCEMENT',
            headerTitle: 'ANNOUNCEMENT',
            is_editable: true,
            is_filterable: true,
            size: 20,
            },
            {
            name: 'KHC_DE_CHAPTER',
            headerTitle: 'KHC_DE_CHAPTER',
            is_editable: true,
            is_filterable: true,
            size: 10,
            },
            {
            name: 'KH_CREATED_BY',
            headerTitle: 'KH_CREATED_BY',
            is_editable: true,
            is_filterable: true,
            size: 10,
            },
            {
                name: 'KH_CREATED_ON',
                headerTitle: 'KH_CREATED_ON',
                is_editable: true,
                is_filterable: true,
                size: 10,
            }
        ],
        tag: 'DE_ANNOUNCEMENTS',
        },
    }
    const hiddenColumns = {
        DE_ANNOUNCEMENTS: [
            'KH_UID',
            'KH_ETL_IS_DELETED',
            'KH_MODIFIED_ON',
            'KH_MODIFIED_BY'
        ],
    }
    // contains list of setTimout ids
    let timeoutIds = []

    // clears all the setTimeout from timeoutIds array
    const clearAllTimeout = () => {
        for (let i = 0; i < timeoutIds.length; i++) {
        clearTimeout(timeoutIds[i])
        }
        timeoutIds = []
    }

    // Default Column

    function DefaultColumnFilter({
        column: { filterValue, setFilter },
        headers,
    }) {
        const [initLoad, setInitLoad] = useState(true)

        useEffect(() => {
        if (initLoad === true) {
            return
        }
        clearAllTimeout()

        let timeoutId = setTimeout(() => {
            let filter_payload = []

            headers.forEach((header_obj, index) => {
            if (header_obj.filterValue !== undefined) {
                let multipleFilter = header_obj.filterValue.split(',')
                multipleFilter.forEach((value) => {
                if (value.length !== 0) {
                    if (value[0] === '"' && value[value.length - 1] === '"') {
                    if (value.length === 2) {
                        let local_obj = { op: 'eq' }
                        local_obj['field'] = header_obj.id
                        local_obj['value'] = null
                        filter_payload.push(local_obj)
                        filter_payload.push({
                        op: 'ilike',
                        field: header_obj.id,
                        value: '',
                        })
                    } else {
                        let local_obj = { op: 'like' }
                        local_obj['field'] = header_obj.id
                        local_obj['value'] =
                        '%' + value.slice(1, value.length - 1) + '%'
                        filter_payload.push(local_obj)
                    }
                    } else if (
                    value[0] === '*' &&
                    value[value.length - 1] === '*'
                    ) {
                    if (value.length === 2) {
                        let local_obj = { op: 'eq' }
                        local_obj['field'] = header_obj.id
                        local_obj['value'] = null
                        filter_payload.push(local_obj)
                        filter_payload.push({
                        op: 'ilike',
                        field: header_obj.id,
                        value: '',
                        })
                    } else {
                        let local_obj = { op: 'eq' }
                        local_obj['field'] = header_obj.id
                        local_obj['value'] = value.slice(1, value.length - 1)
                        filter_payload.push(local_obj)
                    }
                    } else {
                    let local_obj = { op: 'ilike' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = '%' + value + '%'
                    filter_payload.push(local_obj)
                    }
                } else {
                    let local_obj = { op: 'ilike' }
                    local_obj['field'] = header_obj.id
                    local_obj['value'] = '%' + value + '%'
                    filter_payload.push(local_obj)
                }
                })
            }
            })
            setInitLoad(true)
            setColumnFilters(filter_payload)
            fetchData({ pageSize, pageIndex }, JSON.stringify(filter_payload))
        }, 2200)

        timeoutIds.push(timeoutId)
        }, [filterValue, pageSize])
        return (
        <input
            //disabled={disableColumn}
            className="form-control"
            value={filterValue || ''}
            onFocus={() => {
            setInitLoad(false)
            }}
            placeholder="type to search..."
            onChange={(e) => {
            setFilter(e.target.value || undefined)
            }}
        />
        )
    }
    const defaultColumn = {
        Filter: DefaultColumnFilter,
    }
    const available_page_size = ['50', '100', '200', '500']
    const data = useMemo(() => tableData, [hiddenColumns, tableData])
    const columns = useMemo(() => tableColumns, [hiddenColumns, tableColumns])
      // Custome Checkbox component
    const IndeterminateCheckbox = React.forwardRef(
        ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
            <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
        }
    )
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        selectedFlatRows,
        state: { selectedRowIds, pageIndex, pageSize },
        gotoPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
    } = useTable(
        {
        columns,
        data,
        defaultColumn,
        initialState: {
            pageIndex: localPageIndex,
            hiddenColumns: hiddenColumns[table_name],
            pageSize: 50,
            selectedRowIds: { 1: false },
        },
        manualPagination: true,
        manualFilters: true,
        pageCount: totalPages,
        autoResetSortBy: false,
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination,
        useRowSelect,
        (hooks) => {
            hooks.visibleColumns.push((columns) => [
                {
                  id: 'selection',
                  Header: <div></div>,
                  Cell: ({ row, toggleAllRowsSelected, toggleRowSelected }) => {
                    const currentState = row.getToggleRowSelectedProps()
                    return (
                      <IndeterminateCheckbox
                        {...currentState}
                        onClick={() => {
                          toggleAllRowsSelected(false)
                          toggleRowSelected(row.id, !currentState.checked)
                        }}
                      />
                    )
                  },
                },
                ...columns,
              ])
        }
    )
    // used for manual pagination
    const onNext = () => {
        gotoPage(pageIndex + 1)
    }
    const onPrevious = () => {
        gotoPage(pageIndex - 1)
    }
      //handle sorting on columns by updating fetch data sort value
    const handleSorting = (header) => {
        // setSort(true)

        if (columnSort.length !== 0 && columnSort[0].field !== header) {
            setColumnSortValue([
                { field: `${header}`, direction: columnSort[0].direction },
            ])
        } else {
            if (columnSort.length === 0) {
                setColumnSortValue([{ field: `${header}`, direction: 'asc' }])
            } else if (columnSort[0].direction === 'asc') {
                setColumnSortValue([{ field: `${header}`, direction: 'desc' }])
            } else {
                setColumnSortValue([])
            }
        }
    }
    
    const fetchData = async (
        { pageSize = 10, pageIndex = 1 },
        filters = null,
        sort_value = columnSort
      ) => {
        setLoadingStatus({ status: true, message: 'Fetching table records...' })
    
        setLocalPageIndex(pageIndex)
    
        const params = {
          page_size: pageSize,
          page_number: pageIndex + 1, //handle 0 indexing
          table_name: table_name,
          show_deleted: false,
          show_audit_table: false,
          include_deleted: false,
          filters: filters || JSON.stringify(columnFilters),
          sort: sort_value,
        }
    
        dispatch(getAllAnnouncements(endpoint, params))
          .then(() => {
            setLoadingStatus({
              status: false,
              message: 'Fetching table records...',
            })
          })
          .catch(() => {
            setLoadingStatus({
              status: false,
              message: 'Fetching table records...',
            })
          })
    }
    const handleSubmit = () => {
        setLoadingStatus({ status: true, message: 'Fetching table records...' })
        const payload = {
            ANNOUNCEMENT: announcementInput,
            KHC_DE_CHAPTER: chapterInput
        };
        if(UID) {
            let URL = `${endpoint}/api/announcements/update_announcement?table_name=${table_name}&key=${UID}`;
            dispatch(UpdateAnnouncement(URL, payload))
            .then(() => {
                setLoadingStatus({
                    status: false,
                    message: 'Fetching table records...',
                })
                handleCloseModal()
                fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
            })
            .catch(() => {
                setLoadingStatus({
                    status: false,
                    message: 'Fetching table records...',
                })
            })
        } else {
            let URL = `${endpoint}/api/announcements/create_announcement?table_name=${table_name}`;
            dispatch(AddAnnouncement(URL, payload))
            .then(() => {
                setLoadingStatus({
                    status: false,
                    message: 'Fetching table records...',
                })
                handleCloseModal()
                fetchData({ pageSize, pageIndex }, JSON.stringify(columnFilters))
            })
            .catch(() => {
                setLoadingStatus({
                    status: false,
                    message: 'Fetching table records...',
                })
            })
        }
      }
      // updates editRows state on select checkbox event
    useEffect(() => {
        setEditRows(selectedFlatRows.map((d) => d.original))
    }, [selectedFlatRows])
    useEffect(() => {
        let table_data = getAnnouncementData?.data
        if (table_data?.length > 0) {
          setTableColumns(
            TableHandler.createColumnMappingforKholab(
              ['ANNOUNCEMENT', 'KHC_DE_CHAPTER', 'KH_CREATED_BY', 'KH_CREATED_ON'],
              columnMetadata,
              table_name
            )
          )
          setTotalPages(getAnnouncementData?.num_pages)
          setTotalRows(getAnnouncementData?.total_results)
          setTableData(table_data)
        } else {
          setTotalPages(0)
          setTotalRows(0)
          setTableData([])
        }
    }, [setTableData, getAnnouncementData])
    useEffect(() => {
        fetchData(
          { pageSize, pageIndex },
          JSON.stringify(columnFilters),
          JSON.stringify(columnSort)
        )
      }, [pageSize, pageIndex, columnSort])
      // updates localStorage with new list of hiddenColumns
      useEffect(() => {
        localStorage.setItem('hidden_columns', JSON.stringify(hiddenColumns))
      }, [hiddenColumns])
    
    const handleEdit = () =>{
        if (editRows.length > 0) {
            setshowModal(true);
            setUID(editRows[0].KH_UID);
            setAnnouncementInput(editRows[0].ANNOUNCEMENT);
            setChapterInput(editRows[0].KHC_DE_CHAPTER);
        }
    }
    const handleCreate = () => {
        setshowModal(true);
        setUID('');
        setAnnouncementInput('');
        setChapterInput('');
    }
    
    const handleCloseModal = () => {
        setshowModal(false)
      }

    return (
        <Sidebar
            channel={channel}
            setChannel={setChannel}
            Page="Announcements"
            Index={
            
                loadingStatus.status ? (
                    <LoadingStatus status_message={loadingStatus.message} />
                ) : (<>
                    <div className="table-container" style={{ textAlign: 'center' }}>
                        <Table {...getTableProps()}>
                            <thead className="sticky-table-header">
                                {headerGroups.map((headerGroup, i) => (
                                <tr key={i} {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column, i) =>
                                    column?.id === 'selection' ? (
                                        <th key={column.id}>
                                        <div
                                            {...column.getHeaderProps(
                                            column.getSortByToggleProps(),
                                            { style: { width: column.size } }
                                            )}
                                            className="table-header-badge"
                                        >
                                            {column.render('Header')}

                                            {columnSort[0]?.field === column['Header'] ? (
                                            <span>
                                                {columnSort[0]?.direction === 'asc'
                                                ? ' 🔽'
                                                : '' || columnSort[0]?.direction === 'desc'
                                                ? ' 🔼'
                                                : ''}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                        <div>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                        </th>
                                    ) : (
                                        <th key={i}>
                                        <div
                                            onClick={() => handleSorting(column.id)}
                                            className="table-header-badge"
                                        >
                                            {column.render('Header')}

                                            {columnSort[0]?.field === column['id'] ? (
                                            <span>
                                                {columnSort[0]?.direction === 'asc'
                                                ? ' 🔽'
                                                : '' || columnSort[0]?.direction === 'desc'
                                                ? ' 🔼'
                                                : ''}
                                            </span>
                                            ) : (
                                            ''
                                            )}
                                        </div>
                                        <div>
                                            {column.canFilter ? column.render('Filter') : null}
                                        </div>
                                        </th>
                                    )
                                    )}
                                </tr>
                                ))}
                            </thead>
                            {tableData?.length > 0 ? (
                                <tbody className="tbody mybadgesBody" {...getTableBodyProps()}>
                                {page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                    <tr key={index}>
                                        {row.cells.map((cell) => {
                                        return  cell.column.id === 'PROJECT_DIR' &&
                                            cell.value &&
                                            cell.value.length > 60 ? (
                                            <BootstrapTooltip title={cell.render('Cell')}>
                                            <td
                                                key={cell}
                                                {...cell.getCellProps({
                                                style: {
                                                    width: cell.column.size + '%',
                                                    'max-width': '350px',
                                                    'white-space': 'nowrap',
                                                    overflow: 'hidden',
                                                    'text-overflow': 'ellipsis',
                                                },
                                                })}
                                            >
                                                {cell.render('Cell')}
                                            </td>
                                            </BootstrapTooltip>
                                        ) :  (
                                            <td
                                            key={cell}
                                            {...cell.getCellProps({
                                                style: {
                                                width: cell.column.size + '%',
                                                },
                                            })}
                                            >
                                            {cell.render('Cell')}
                                            </td>
                                        )
                                        })}
                                    </tr>
                                    )
                                })}
                                </tbody>
                            ) : (
                                <tbody>
                                <tr>
                                    <td colSpan={4}>No Announcements Available.</td>
                                </tr>
                                </tbody>
                            )}
                        </Table>
                    </div>
                    <Row>
                        <Col sm={8}>
                        <div className="page-control">
                            <div className="page-of">
                            Page{' '}
                            <em>
                                {pageIndex + 1} of {totalPages}
                            </em>
                            </div>
                            <div className="prev-next-btn">
                            <button
                                className="badge-btn"
                                onClick={() => onPrevious()}
                                disabled={!canPreviousPage}
                            >
                                {' '}
                                Prev{' '}
                            </button>
                            <button
                                onClick={() => onNext()}
                                className="badge-btn"
                                disabled={!canNextPage}
                            >
                                {' '}
                                Next{' '}
                            </button>
                            </div>
                            <div className="second-control">
                            <span>Go to page:</span>
                            <input
                                className="page-number-input"
                                type="number"
                                defaultValue={pageIndex || 1}
                                onBlur={(e) => {
                                const page = e.target.value ? Number(e.target.value) : 0
                                // handling zero indexing
                                gotoPage(page - 1)
                                }}
                            />
                            <select
                                value={pageSize}
                                onChange={(e) => {
                                setPageSize(Number(e.target.value))
                                }}
                            >
                                {available_page_size.map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                                ))}
                            </select>
                            </div>
                            <div
                            style={{
                                display: 'inline-block',
                                marginLeft: '24px',
                                fontWeight: 600,
                                fontSize: '14px',
                            }}
                            >
                            {' '}
                            Total results:{' '}
                            {getAnnouncementData?.total_results}
                            </div>
                        </div>
                        </Col>
                        <Col sm={4}>
                            <Button className="m_r-5 block-btn1 main-button btn-sm" onClick={handleEdit} >EDIT</Button>
                            <Button className="m_r-5 block-btn1 main-button btn-sm" onClick={handleCreate} >CREATE</Button>
                        </Col>
                        
                    </Row>
                    <Modal size="md" show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>{UID? 'Edit': 'Create'} Announcement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="modalBody">
                            <Form>
                                <Container className="m-0">
                                    <Row className="mb-3 mr-3 rowFlex">
                                        <Form.Group className="" as={Col} md="12" controlId="validationFormik01">
                                            <Form.Label>Announcement</Form.Label>
                                            <InputGroup>
                                                <Form.Control type="textarea" defaultValue={announcementInput} onChange={(e) => setAnnouncementInput(e.target.value)} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3 mr-3 rowFlex">
                                        <Form.Group className="flexItem" as={Col} md="4">
                                            <Form.Label>Channel</Form.Label>
                                            <Form.Select value={chapterInput} onChange={(e) => setChapterInput(e.target.value)} >
                                                <option value="">None</option>
                                        {JSON.parse(localStorage.getItem('badge_metadata'))?.channels?.map((chapter, index) => {
                                            return (
                                                <option value={chapter} key={index}>
                                                    {chapter}
                                                </option>
                                            )
                                        })}
                                            </Form.Select>
                                        </Form.Group>
                                    </Row>
                                </Container>
                            </Form>
                            <Modal.Footer>
                                <Button variant="primary" type="submit" className="addModalBtn"
                                onClick={handleSubmit}>
                                Submit
                                </Button>
                                <Button variant="danger" type="submit" className="addModalBtn"
                                onClick={handleCloseModal}>
                                Close
                                </Button>
                            </Modal.Footer>
                                
                        </Modal.Body>
                    </Modal>
                </>
                )
            
            }
        />
    )
}
