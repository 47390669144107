import React from 'react'
import './index.css'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { Typography } from '@mui/material'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
export default function Input({
  objValue,
  onChange,
  index,
  deleteField,
  disabled,
}) {
  const { label, placeholder, type, value, ratingType, options } = objValue
  const newArr = options.filter(
    (element) => element !== null && element !== '' && element !== undefined
  )
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ]
  function valuetext(value) {
    return `${value}`
  }

  return (
    <div className="input-group-nps">
      {type == 'text-read-only' ? (
        <></>
      ) : (
        <label htmlFor={label}>{label}</label>
      )}
      {type == 'radio' ? (
        <div className="input-nps">
          {newArr?.map((option, i) => {
            return (
              <div key={i} className="radio-input-nps">
                <input
                  type="radio"
                  id={option}
                  value={option}
                  checked={option == value ? true : false}
                  onChange={(e) => onChange(e, index)}
                  disabled={
                    disabled == 'true' || disabled == true ? true : false
                  }
                />
                <label className="radio-label-nps" htmlFor={option}>
                  {option}
                </label>
              </div>
            )
          })}
          {disabled == 'true' ? (
            <> </>
          ) : (
            <div onClick={(e) => deleteField(e, index)}>X</div>
          )}
        </div>
      ) : type == 'checkbox' ? (
        <div className="input-nps">
          {newArr?.map((option, i) => {
            return (
              <div key={i} className="radio-input-nps">
                <input
                  type="checkbox"
                  id={option}
                  value={option}
                  checked={
                    objValue?.checkboxValue?.includes(option) ? true : false
                  }
                  onChange={(e) => onChange(e, index)}
                  disabled={
                    disabled == 'true' || disabled == true ? true : false
                  }
                />
                <label className="radio-label-nps" htmlFor={option}>
                  {option}
                </label>
              </div>
            )
          })}
          {disabled == 'true' ? (
            <> </>
          ) : (
            <div onClick={(e) => deleteField(e, index)}>X</div>
          )}
        </div>
      ) : type == 'Nps' ? (
        <>
           <div>
            <div style={{width:"100%"}}>
            <Box className="input-nps">
              <Slider
                aria-label="nps score"
                getAriaValueText={valuetext}
                valueLabelDisplay="auto"
                step={1}
                marks={marks}
                disabled={disabled == 'true' || disabled == true ? true : false}
                min={0}
                defaultValue={value}
                max={10}
              />
            </Box>
            <Typography id="non-linear-slider" gutterBottom>
        <div style={{display:"flex", marginTop:"10px"}}><div style={{fontWeight:"600"}}>Not Confident</div> <div style={{fontWeight:"600", marginLeft:"auto"}}>Very Confident </div></div>
      </Typography>
      </div>
            {disabled == 'true' ? (
              <></>
            ) : (
              <div
                className="textarea-cancel"
                onClick={(e) => deleteField(e, index)}
              >
                X
              </div>
            )}
          </div>
        </>
      ) : type == 'textarea' ? (
        <div>
          <div className="input-nps">
            <textarea
              id={label}
              placeholder={placeholder || ''}
              onChange={(e) => onChange(e, index)}
              rows="4"
              cols="70"
              defaultValue={value}
              disabled={disabled == 'true' || disabled == true ? true : false}
            />
          </div>
          {disabled == 'true' ? (
            <></>
          ) : (
            <div
              className="textarea-cancel"
              onClick={(e) => deleteField(e, index)}
            >
              X
            </div>
          )}
        </div>
      ) : type == 'text-read-only' ? (
        <>
          <p>{parse(label)}</p>
        </>
      ) : type == 'rating' ? (
        <>
          <div className="input-nps">
            {ratingType == 'Heart' ? (
              <StyledRating
                name="customized-color"
                defaultValue={value}
                getLabelText={(value) =>
                  `${value} Heart${value !== 1 ? 's' : ''}`
                }
                precision={1}
                style={{ color: 'red' }}
                icon={<FavoriteIcon fontSize="inherit" />}
                emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
              />
            ) : ratingType == 'star' ? (
              <Rating
                name="half-rating"
                defaultValue={value}
                precision={1}
                readOnly
              />
            ) : ratingType == 'faces' ? (
              <>
                <StyledRating
                  name="highlight-selected-only"
                  defaultValue={value}
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  highlightSelectedOnly
                />{' '}
              </>
            ) : (
              <></>
            )}

            {disabled == 'true' ? (
              <></>
            ) : (
              <div
                className="textarea-cancel"
                onClick={(e) => deleteField(e, index)}
              >
                X
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {' '}
          <div className="input-nps">
            <input
              type={type || 'text'}
              id={label}
              value={value || ''}
              placeholder={placeholder || ''}
              onChange={(e) => onChange(e, index)}
              disabled={disabled == 'true' || disabled == true ? true : false}
            />
            {disabled == 'true' ? (
              <></>
            ) : (
              <div onClick={(e) => deleteField(e, index)}>X</div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
