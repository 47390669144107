import CardsView from '../../Utilities/cardView'
import { useNavigate } from 'react-router-dom'
import NpsMenu from '../chapterNpsMenu'
import Sidebar from '../../AppBar/Appbar'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import { useContext, useEffect, useState } from 'react'
import { getUserSurvey } from '../../../../redux/actions/npsActions/npsSurveyAction'
import ReactPaginate from 'react-paginate'
import LoadingStatus from '../../../Helper/LoadingStatus'
import '../indexChapter.css'
import './userForm.css'
import { toast } from 'react-toastify'
import { getForumMetadata } from '../../../../redux/actions/forumMetadataAction'
import { BootstrapTooltip } from '../../Ideas/IdeaFunction'
function ShowAllUserForms() {
  const [authCreds] = useContext(AuthContext)
  const [currentPage, setCurrentPage] = useState(0)
  const [cardData, setCardData] = useState([])
  const [sort, setSort] = useState('asc')
  const [totalPages, setTotalPages] = useState(0)
  const [statusSort, setStatusSort] = useState('Pending')
  const [bgcolor, setbgcolor] = useState('')
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: '',
  })
  const [collabMetadata, setCollabMetadata] = useState('')
  const [channel, setChannel] = useState(
    localStorage.getItem('channel') == null
      ? ''
      : localStorage.getItem('channel')
  )
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const endpoint = authCreds.restEndpoint
  const getUserSurveyData = useSelector((state) => state.getUserSurveyData)

  let history = useNavigate()
  const dispatch = useDispatch()

  const handleCard = (id) => {
    if (statusSort == 'Pending') {
      history(`/survey/${id}`)
    } else {
      return toast.error('Form already submitted')
    }
  }

  useEffect(() => {
    if (getUserSurveyData?.loading === false) {
      setCardData(getUserSurveyData?.getUserSurveyData?.data)
      setTotalPages(
        Math.ceil(getUserSurveyData?.getUserSurveyData?.total_results / 10)
      )
    }
  }, [getUserSurveyData, setCardData])

  useEffect(() => {
    if (statusSort == 'Pending') {
      setbgcolor('#234783')
    } else {
      setbgcolor('#048732')
    }
  }, [bgcolor, setbgcolor, statusSort])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    const params = {
      page_size: 10,
      page_number: currentPage + 1,
      sort:
        sort == 'latest'
          ? []
          : JSON.stringify([{ field: 'form_name', direction: sort }]),
      filters: JSON.stringify([
        { op: 'eq', field: 'status', value: `${statusSort}` },
        { op: 'eq', field: 'channel', value: `${channel}` },
      ]),
    }
    dispatch(getUserSurvey(endpoint, params)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [sort, currentPage, statusSort, channel])

  const handlePageChange = (number) => {
    setCurrentPage(number.selected)
  }
  useEffect(() => {
    async function forumMetadata() {
      // setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url)).then(() => {
        // setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  useEffect(() => {
    if (forumMetadata?.loading === false) {
      setCollabMetadata(forumMetadata?.forumMetadata)
    }
  }, [forumMetadata])
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <>
          <Sidebar
            Page="NPS"
            setChannel={setChannel}
            channel={channel}
            collabMetadata={collabMetadata}
            Index={
              <div className="main-section-nps-chapter">
                <NpsMenu
                  sort={sort}
                  setSort={setSort}
                  setStatusSort={setStatusSort}
                  statusSort={statusSort}
                />
                <div className="cards" style={{ marginTop: '10px' }}>
                  {cardData?.map((result) => (
                    <CardsView
                      key={result.id}
                      handleClick={() => handleCard(result?.id)}
                      // onClick={() => handleCard(result?.id)}
                      title_content={
                        <>
                          {' '}
                          {result.form_name && result.form_name.length > 35 ? (
                            <BootstrapTooltip title={result.form_name}>
                              <span className="nps_survey_title">
                                {result.form_name}
                              </span>
                            </BootstrapTooltip>
                          ) : (
                            <span className="nps_survey_title">
                              {result.form_name}
                            </span>
                          )}
                          {result.description &&
                          result.description.length > 50 ? (
                            <BootstrapTooltip title={result.description}>
                              <span className="nps_survey_desc">
                                {result.description}
                              </span>
                            </BootstrapTooltip>
                          ) : (
                            <span className="nps_survey_desc">
                              {result.description}
                            </span>
                          )}
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                              height: 'fit-content',
                            }}
                          >
                            <span className="date-label">STATUS</span>{' '}
                            <span className={`survey_status`}>
                              {statusSort}
                            </span>
                          </div>
                        </>
                      }
                      border_status_class={
                        statusSort == 'Pending'
                          ? 'border-primary card-height'
                          : 'border-success card-height'
                      }
                      action_content={
                        <>
                          <div
                            style={{
                              display: 'flex',
                              flexGrow: '1',
                              justifyContent: 'flex-start',
                              color: '#0e0eed',
                            }}
                          >
                            <span className="date-label">START DATE:</span>
                            <span className="date-label-count">
                              {
                                new Date(result?.start_date)
                                  .toISOString()
                                  .split('T')[0]
                              }
                            </span>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexGrow: '1',
                              justifyContent: 'flex-end',
                              color: '#d30000',
                            }}
                          >
                            <span className="date-label">END DATE:</span>
                            <span className="date-label-count">
                              {
                                new Date(result?.end_date)
                                  .toISOString()
                                  .split('T')[0]
                              }
                            </span>
                          </div>
                        </>
                      }
                    />
                  ))}
                </div>
                <ReactPaginate
                  pageCount={totalPages}
                  onPageChange={handlePageChange}
                  forcePage={currentPage}
                  breakLabel={'...'}
                  previousLabel={'<<'}
                  nextLabel={'>>'}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            }
          />
        </>
      )}
    </>
  )
}

export default ShowAllUserForms
