import { useState, useRef, useEffect, useContext } from 'react'
import Input from '../input'
import '../indexChapter.css'
import { toast } from 'react-toastify'
import { Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import ReactQuill from 'react-quill'
import { AuthContext } from '../../../context/authProvider'
import {
  editSurvey,
  getSurvey,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import LoadingStatus from '../../../Helper/LoadingStatus'
import { DateHandler } from '../../Utilities/dateutils'
import SelectAsyncPaginate from '../../Utilities/selectPaginate'

function AdminViewForm({ surveyId }) {
  const [formValues, setFormValues] = useState([])
  const [toggle, setToggle] = useState(false)
  const [groupChoiceList, setGroupChoiceList] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [enableEdit, setEnableEdit] = useState(true)
  const [fieldValue, setFieldValue] = useState('text')
  const [counter, setCounter] = useState(1)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [active, setActive] = useState(false)
  const [fieldValueRating, setFieldValueRating] = useState('star')
  const [channel, setChannel] = useState('')
  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: 'Fetching table records...',
  })
  const [authCreds] = useContext(AuthContext)

  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const getSurveyData = useSelector((state) => state.getSurveyData)
  const inputRef = useRef()
  const placeholderRef = useRef()
  const inputRefs = useRef([])
  const pushRef = (el) => {
    inputRefs.current.push(el)
  }

  const selectRef = useRef()
  const selectRefRating = useRef()

  const handleDropdownRating = (selectRefRating) => {
    setFieldValueRating(selectRefRating.current.value)
  }
  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (getSurveyData?.loading == false) {
      let groupNames =
        getSurveyData?.getSurveyData?.data?.active_survey_group_list?.map(
          (res) => {
            return { value: res?.label, label: res?.label, id: res?.id }
          }
        )
      setGroupChoiceList(groupNames)
      setFormValues(getSurveyData?.getSurveyData?.data?.survey_json)
      setTitle(getSurveyData?.getSurveyData?.data?.form_name)
      setDescription(getSurveyData?.getSurveyData?.data?.description)
      setActive(getSurveyData?.getSurveyData?.data?.is_active)
      setChannel(getSurveyData?.getSurveyData?.data?.channel)
      setStartDate(
        DateHandler.dateTimetoDateConverter(
          getSurveyData?.getSurveyData?.data?.start_date
        )
      )
      setEndDate(
        DateHandler.dateTimetoDateConverter(
          getSurveyData?.getSurveyData?.data?.end_date
        )
      )
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    }
  }, [getSurveyData])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getSurvey(endpoint, surveyId, 'True')).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [])

  const handleChange = (e, index) => {
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleDeleteField = (e, index) => {
    const values = [...formValues]
    values.splice(index, 1)
    setFormValues(values)
  }
  const clearArray = () => {
    inputRefs.current.length = 0
  }
  const handleAddField = (e) => {
    if (
      inputRef.current.value === '' ||
      inputRef.current.value === null ||
      placeholderRef?.current?.value === '' ||
      placeholderRef?.current?.value === null
    ) {
      toast.error('Please enter values')
    } else {
      e.preventDefault()
      const values = [...formValues]

      let options = inputRefs?.current?.map((n) => {
        return n?.value
      })
      let newOption = options.filter(function (element) {
        return element !== undefined
      })
      let newOption1 = [...new Set(newOption)]
      if (newOption1[0] == '') {
        toast.error('Please enter options')
      } else {
        values.push({
          label: inputRef.current.value || 'label',
          placeholder: placeholderRef?.current?.value || '',
          type: selectRef.current.value || 'text',
          value: '',
          ratingType: fieldValueRating,
          options: newOption1 || [],
        })
        setFormValues(values)
        setToggle(false)
        setFieldValue(selectRef.current.value)
        clearArray()
      }
    }
  }
  const modules = {
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
      ],
      ['link'],
      ['clean'],
      ['code'],
    ],
  }

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'code',
  ]
  const handleChangeCheck = () => {
    setActive(!active)
  }

  const addBtnClick = (e) => {
    e.preventDefault()
    setToggle(true)
    clearArray()
    setFieldValue('text')
    setCounter(1)
  }
  function optionFunc(c) {
    var MultiGold = []
    Array.from(Array(c), (e, i) => {
      MultiGold.push(
        <input
          className="options"
          type="text"
          placeholder="Add option"
          ref={pushRef}
          key={i}
        />
      )
    })
    return <div>{MultiGold}</div>
  }
  const handleDropdown = (selectRef) => {
    setFieldValue(selectRef.current.value)
  }
  const handleSubmit = () => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })
    if (startDate == undefined || endDate == undefined) {
      toast.error('Please add start and end date')
    } else {
      const groupID = groupChoiceList.map((object) => object.id)

      const params = {
        id: surveyId,
        form_name: title,
        description: description,
        created_on: getSurveyData?.getSurveyData?.data?.created_on,
        created_by: getSurveyData?.getSurveyData?.data?.created_by,
        is_active: active,
        is_private: true,
        nps_score_perc: getSurveyData?.getSurveyData?.data?.nps_score_perc,
        survey_comp_perc: getSurveyData?.getSurveyData?.data?.survey_comp_perc,
        survey_group_ids: groupID,
        start_date: startDate,
        end_date: endDate,
        form_json: formValues,
        channel: channel,
      }
      dispatch(editSurvey(endpoint, params)).then(() => {
        setEnableEdit(false)
        dispatch(getSurvey(endpoint, surveyId, 'True')).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
        })
      })
    }
  }

  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="nps">
          <form className="nps-form-main">

            <input
              type="text"
              placeholder="Add Title"
              onChange={(e) => setTitle(e.target.value)}
              className="titleInput"
              defaultValue={getSurveyData?.getSurveyData?.data?.form_name}
              // disabled={!enableEdit}
            />
            <textarea
              id="w3review"
              className="descInput"
              name="w3review"
              rows="4"
              cols="50"
              placeholder="Add Description"
              onChange={(e) => setDescription(e.target.value)}
              defaultValue={getSurveyData?.getSurveyData?.data?.description}
            />
            {formValues?.map((obj, index) => (
              <Input
                key={index}
                objValue={obj}
                onChange={handleChange}
                index={index}
                deleteField={handleDeleteField}
                // disabled={!enableEdit}
              />
            ))}
            {!toggle && enableEdit ? (
              <>
                <div className="center-nps">
                  <button className="add-btn-nps-1" onClick={addBtnClick}>
                    Add new
                  </button>
                </div>
              </>
            ) : enableEdit ? (
              <>
                <div className="dialog-box-nps">
                  <select
                    ref={selectRef}
                    onChange={() => handleDropdown(selectRef)}
                  >
                    <option value="text">Text</option>
                    <option value="textarea">Text Area</option>
                    <option value="text-read-only">Read Only Text</option>
                    <option value="radio">Radio</option>
                    <option value="checkbox">CheckBox</option>
                    <option value="rating">Rating</option>
                    <option value="Nps">Net Promoter Score</option>
                  </select>
                  <input
                    type="text"
                    placeholder="Add label"
                    className="form-fields"
                    ref={inputRef}
                  />
                  {fieldValue === 'text' || fieldValue == 'textarea' ? (
                    <input
                      type="text"
                      placeholder="Add placeholder"
                      ref={placeholderRef}
                      className="form-fields"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div style={{ marginTop: '11px', marginBottom: '11px' }}>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                  />
                  <label for="start" className="require">
                    {' '}
                    Required*
                  </label>
                </div>
                {fieldValue == 'radio' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'checkbox' ? (
                  <>
                    <div className="option-block">
                      {optionFunc(counter)}
                      <button
                        type="button"
                        onClick={() => setCounter(counter + 1)}
                        className="option-btn"
                      >
                        +
                      </button>
                      {counter > 1 ? (
                        <button
                          type="button"
                          onClick={() => setCounter(counter - 1)}
                          className="option-btn"
                        >
                          -
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'text-read-only' ? (
                  <>
                    <ReactQuill
                      theme="snow"
                      style={{ background: '#fff' }}
                      // value={formData.definition}
                      // onChange={handleRichTextChange}
                      modules={modules}
                      formats={formats}
                      ref={inputRef}
                      className="rich-text-editor"
                      required
                    />
                  </>
                ) : (
                  <></>
                )}
                {fieldValue == 'rating' ? (
                  <>
                    <select
                      ref={selectRefRating}
                      onChange={() => handleDropdownRating(selectRefRating)}
                      style={{
                        width: '20%',
                        border: '1px solid #9d9eff',
                        padding: '5px',
                        outline: 'none',
                      }}
                    >
                      <option value="star">Star</option>
                      <option value="Heart">Heart</option>
                      <option value="faces">Faces</option>
                    </select>
                  </>
                ) : (
                  <></>
                )}
                <button
                  className="add-btn-nps"
                  type="button"
                  onClick={handleAddField}
                >
                  Add
                </button>
              </>
            ) : (
              <></>
            )}
          </form>
          {formValues.length > 0 ? (
            <div className="nps-form-main">
              <div>
                <p style={{ margin: '7px' }}>Survey Group </p>
              </div>

              <div style={{ width: '100%' }}>
                <SelectAsyncPaginate
                  onChange={(choice) => setGroupChoiceList(choice)}
                  endpointUrl="api/survey/get_survey_group_list"
                  defaultValue={groupChoiceList}
                  isDisabled={true}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
          {formValues.length > 0 ? (
            <div className="nps-form-main">
              <h2 className="sub-head-nps">Scheduling</h2>
              <>
                <div style={{ marginTop: '20px' }}>
                  <div style={{ display: 'flex' }}>
                    <label for="start" className="date-label">
                      Start date:
                    </label>
                    <input
                      type="date"
                      className="date-field"
                      value={startDate}
                      min={new Date().toISOString().split('T')[0]}
                      // disabled={!enableEdit}
                      onChange={(event) => setStartDate(event.target.value)}
                    />{' '}
                    <label for="start" className="date-label">
                      End date:
                    </label>
                    <input
                      className="date-field"
                      type="date"
                      value={endDate}
                      min={new Date().toISOString().split('T')[0]}
                      onChange={(event) => setEndDate(event.target.value)}
                    />{' '}
                    <input
                      type="checkbox"
                      className="check-field"
                      onChange={handleChangeCheck}
                      checked={active}
                    />
                    <label for="start" className="date-label">
                      Is active
                    </label>
                  </div>
                </div>
              </>
            </div>
          ) : (
            <></>
          )}
          <button
            type="button"
            className="submit-btn-nps-edit"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      )}
    </>
  )
}
export default AdminViewForm
