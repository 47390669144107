import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Modal from 'react-bootstrap/Modal'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import ReactPaginate from 'react-paginate'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import axios from 'axios'

export const sortByMapping = [
  'Like',
  'Latest',
  'View',
  // 'Title'
]
export const orderByMapping = ['desc', 'asc']
export const categoryArr = {
    Technical: 'Technical',
    Ways_of_working: 'Ways of Working',
}
export const statusArr = {
  Unassigned: 'Unassigned',
  'In Progress': 'In Progress',
  Completed: 'Completed',
  Rejected: 'Rejected',
  Deprecated: 'Deprecated'
}
export const complexityArr = { XS: 'XS', S: 'S', M: 'M', L: 'L', XL: 'XL' }
const availablePageSize = ['12','50', '100', '200', '500']

export const getReviewCount = async (endpoint, channel) => {
  const params = { page: 1, per_page: 1 }
  let filtersArr = []
  filtersArr.push(`{"op":"eq","field":"status","value":"New"}`)
  if (channel && channel !== '') {
    filtersArr.push(`{"op":"eq","field":"chapter","value":"${channel}"}`)
  }
  params.filters = '[' + filtersArr.join(',') + ']'
  const url = `${endpoint}/api/ideas/get_all_ideas`
  let post_headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
    khuser: `${localStorage.getItem('user')}`,
  }
  let count = 0;
  try {
  const response = await axios
    .get(url, {
      params: params,
      headers: post_headers,
    });
    count = response?.data?.total_results; 
    return count;
  } catch (err) {
    // Error handling here
    return count;
 }
}

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
export const status_label = (status) => {
  let status_label_arr = {
    badge_status_class: '',
    border_status_class: '',
  }

  switch (status) {
    case 'New':
      status_label_arr.badge_status_class = 'badge-warning'
      status_label_arr.border_status_class = 'border-warning'
      break
    case 'Unassigned':
      status_label_arr.badge_status_class = 'badge-primary'
      status_label_arr.border_status_class = 'border-primary'
      break
    case 'In Progress':
      status_label_arr.badge_status_class = 'badge-info'
      status_label_arr.border_status_class = 'border-info'
      break
    case 'Completed':
      status_label_arr.badge_status_class = 'badge-success'
      status_label_arr.border_status_class = 'border-success'
      break
    case 'Rejected':
      status_label_arr.badge_status_class = 'badge-danger'
      status_label_arr.border_status_class = 'border-danger'
      break
    case 'Deprecated':
      status_label_arr.badge_status_class = 'badge-deprecate'
      status_label_arr.border_status_class = 'border-default'
      break
    default:
      break
  }
  return status_label_arr
}

export const AddIdeasModel = ({
  showAddIdeas,
  handleCloseAddIdeas,
  handleCreateIdea,
  channel,
  setIdeaTitle,
  setDescripiton,
  setCategory,
  setChapter,
  setComplexity,
  categoryArr,
  complexityArr,
}) => {
  return(
    <Modal show={showAddIdeas} onHide={handleCloseAddIdeas}>
      <Modal.Header closeButton>
        <Modal.Title>Create Ideas</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modalBody">
        <Form>
          <Container className="m-0">
            <Row className="mb-3 mr-3 rowFlex">
              <Form.Group className="flexItem col-4" as={Col} md="4">
                <Form.Label>
                  Title<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Idea Title"
                  autoFocus
                  onChange={(e) => {
                    setIdeaTitle(e.target.value)
                  }}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3 mr-3 rowFlex">
              <Form.Group className="flexItem col-1" as={Col} md="4">
                <Form.Label>
                  Category<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                >
                  <option key="placeholder" value="">
                    Select Category
                  </option>
                  {Object.entries(categoryArr).map(([key, val]) => {
                    return (
                      <option key={key} value={key}>
                        {val}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3 mr-3 rowFlex">
              <Form.Group className="flexItem col-1" as={Col} md="4">
                <Form.Label>
                  Chapter<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setChapter(e.target.value)
                  }}
                  value={channel}
                >
                  <option key="placeholder" value="">
                    Select Chapter
                  </option>
                  {JSON.parse(
                    localStorage.getItem('channel_metadata')
                  )
                    ?.channels?.sort()
                    ?.map?.((chapter, index) => {
                      return (
                        <option key={index} value={chapter}>
                          {chapter}
                        </option>
                      )
                    })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3 mr-3 rowFlex">
              <Form.Group className="flexItem col-1" as={Col} md="4">
                <Form.Label>
                  Complexity<span className="text-danger">*</span>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  onChange={(e) => {
                    setComplexity(e.target.value)
                  }}
                >
                  <option key="placeholder" value="">
                    Select Complexity
                  </option>
                  {Object.entries(complexityArr).map(([key, val]) => {
                    return (
                      <option key={key} value={val}>
                        {val}
                      </option>
                    )
                  })}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-3 mr-3 rowFlex">
              <Form.Group className="flexItem" as={Col} md="4">
                <Form.Label>
                  Description<span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  onChange={(e) => {
                    setDescripiton(e.target.value)
                  }}
                />
              </Form.Group>
            </Row>
          </Container>
        </Form>
        <Modal.Footer style={{ gap: '10px' }}>
          <Button
            variant="primary"
            className="addModalBtn"
            type="submit"
            onClick={handleCreateIdea}
            style={{ color: '#1976d2', border: '1px solid #0d6efd' }}
          >
            Submit
          </Button>
          <Button
            variant="danger"
            type="submit"
            className="addModalBtn"
            onClick={handleCloseAddIdeas}
            style={{ color: '#dc3545', border: '1px solid #dc3545' }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export const IdeasPagination = ({
  totalPages,
  handlePageChange,
  currentPage,
  totalResults,
  pageSize,
  setPageSize
}) => {
  return(
    <div style={{display:'flex', flexDirection:'row',border: '.7px solid #2b2b2b'}}>
      <ReactPaginate
        pageCount={totalPages}
        onPageChange={handlePageChange}
        forcePage={currentPage}
        breakLabel={'...'}
        previousLabel={'<<'}
        nextLabel={'>>'}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination idea-pagination"
        activeClassName="active"
        style={{marginBottom:0}}
      />
      <div style={{ display: 'flex', marginRight: '8px'}}>
        <DropdownButton
          id="dropdown-order-by"
          title={`Show: ${pageSize}`}
          className="glossary-sort-buttons glossary-sort-buttons-idea glossary-sort-idea-pagination-buttons"
          onSelect={(eventKey) => setPageSize(eventKey)}
        >
          {availablePageSize.map((pageSize) => (
            <Dropdown.Item eventKey={pageSize} key={pageSize}>
              {pageSize}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      <div style={{display:'flex', alignItems:'center'}}><span style={{fontWeight: 600, fontSize: '14px', marginRight: '5px'}}>Total results:</span>{totalResults}</div>
    </div>
  )
}

// export default AddIdeasModel;
