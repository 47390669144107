import {
    GET_ANNOUNCEMENT_ERROR,
    GET_ANNOUNCEMENT,
    GET_ANNOUNCEMENT_LOAD
  } from '../../constants/types'
  
  const initialState = {
    getAllAnnouncements: [],
    loading: false,
    error: null,
  }
  
  export default function (state = initialState, action) {
    switch (action.type) {
      case GET_ANNOUNCEMENT_LOAD:
        return {
          ...state,
          loading: true,
          error: null,
        }
      case GET_ANNOUNCEMENT:
        return {
          ...state,
          getAllAnnouncements: action.payload,
          loading: false,
          error: null,
        }
      case GET_ANNOUNCEMENT_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }
  
      default:
        return state
    }
  }
  
