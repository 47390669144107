import React, { useState, useContext, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import '../AddQuestion/index.css'
// import Editor from 'react-quill/lib/toolbar'
import { TagsInput } from 'react-tag-input-component'
// import { selectUser } from '../../feature/userSlice'
import { useNavigate } from 'react-router-dom'
// import ChipsArray from "./TagsInput";
import jwt_decode from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import {
  EditCollabEntry,
  subscribePost,
} from '../../../../../redux/actions/collabAction'
import { getForumMetadata } from '../../../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../../../context/authProvider'
import LoadingStatus from '../../../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { toastTopRightError } from '../../../../Helper/ToastObjects'
import 'quill-mention'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { ForumHandler } from '../../../../Utils'
import MentionHandler from '../../../Utilities/quillSearch'

function EditPost({ questionData, tagValue }) {
  let [channel, setChannel] = React.useState(questionData?.channel)

  function handleChange(e) {
    setChannel(e.target.value)
  }

  let userFetchTimeOut
  let tokenValue
  let tokenValues = localStorage.getItem('token')
  if (tokenValues) {
    tokenValue = jwt_decode(tokenValues)
  }

  const [authCreds] = useContext(AuthContext)

  const forumMetadata = useSelector((state) => state.forumMetadata)
  //react quill source callback
  const quillSearch = (searchTerm, renderList, mentionChar) => {
    clearTimeout(userFetchTimeOut)
    if (searchTerm.length >= 3) {
      userFetchTimeOut = setTimeout(() => {
        ForumHandler.fetchUserData(
          endpoint,
          searchTerm,
          renderList,
          mentionChar
        )
      }, 1000)
    }
  }

  const module = MentionHandler.mentionconfig(quillSearch)
  const [title, setTitle] = useState(questionData?.title)
  // const [body, setBody] = useState('')
  const [editorContent, setEditorContent] = useState(questionData?.description)
  const [tag, setTag] = useState(tagValue)
  const [loadingStatus, setLoadingStatus] = React.useState({
    status: false,
    message: 'Fetching table records...',
  })
  const history = useNavigate()
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  const handleQuill = (value) => {
    setEditorContent(value)
  }
  let search = window.location.search
  const params = new URLSearchParams(search)
  const id = params.get('q')

  const handleSubcriptionMulti = async (values) => {
    setLoadingStatus({ status: true, message: 'Fetching data...' })
    const body = {
      user_email: values,
      post_id: id,
    }

    dispatch(subscribePost(endpoint, body, false)).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      history('/forum')
    })
  }
  const handleSubmit = async () => {
    setLoadingStatus({ status: true, message: 'Question Adding...' })

    if (
      title !== '' &&
      editorContent.replace(/<(.|\n)*?>/g, '').trim().length !== 0 &&
      tag.length !== 0
    ) {
      const regex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi
      const matches = editorContent.match(regex)
      let mentionVal = matches ? matches.map((mention) => mention.slice(0)) : []
      const bodyJSON = {
        title: title,
        description: editorContent,
        tags: tag,
        channel: channel,
        user_email: questionData?.user_email.toLowerCase(),
        multimedia_path: '',
        view_count: questionData?.view_count,
        mentioned_emails: mentionVal,
        vote_count: questionData?.vote_count,
        answer_count: questionData?.answer_count,
        comment_count: questionData?.comment_count,
        created_on: questionData?.created_on,
        created_by: questionData?.created_by.toLowerCase(),
        modified_by: tokenValue?.preferred_username.toLowerCase(),
      }
      dispatch(EditCollabEntry(endpoint, id, bodyJSON)).then(() => {
        2
        if (mentionVal.length > 0) {
          dispatch(handleSubcriptionMulti(mentionVal))
        } else {
          setLoadingStatus({ status: false, message: 'Fetching data...' })
          history('/forum')
        }
      })
    } else {
      setLoadingStatus({ status: false, message: 'Fetching data...' })
      toast('Please Update mandatory fields', toastTopRightError)
    }
  }

  useEffect(() => {
    async function forumMetadata() {
      setLoadingStatus({ status: true, message: 'Fetching data...' })

      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=Q_AND_A_FORUM`
      dispatch(getForumMetadata(url)).then(() => {
        setLoadingStatus({ status: false, message: 'Fetching data...' })
      })
    }
    forumMetadata()
  }, [])

  return (
    <>
      {loadingStatus.status || forumMetadata?.loading ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="add-question">
          <div className="add-question-container">
            <div className="head-title">
              <h1>Update question</h1>
            </div>
            <div className="question-container">
              <div className="question-options">
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Title
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>{' '}
                    <small>
                      Be specific and imagine you’re asking a question to
                      another person
                    </small>
                    <input
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      type="text"
                      placeholder="e.g Is there an R function for finding teh index of an element in a vector?"
                    />
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Body
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>{' '}
                    <small>
                      Include all the information someone would need to answer
                      your question
                    </small>
                    <ReactQuill
                      style={{ background: '#fff' }}
                      theme="snow"
                      className="react-quill"
                      defaultValue={questionData?.description}
                      onChange={handleQuill}
                      modules={module}
                      // formats={allowedFormats}
                    />
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Tags
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>{' '}
                    <small>
                      Add up to 5 tags to describe what your question is about
                    </small>
                    <TagsInput
                      value={tag}
                      style={{ background: '#fff' }}
                      onChange={setTag}
                      name="tags"
                      placeHolder="press enter to add new tag"
                    />
                  </div>
                </div>
                <div className="question-option">
                  <div className="title">
                    <h3 style={{ display: 'flex' }}>
                      Channel
                      <p style={{ color: 'red', fontSize: '20px' }}>*</p>
                    </h3>
                    <FormControl
                      variant="standard"
                      sx={{ m: 1, minWidth: 120 }}
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Select Channel Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={channel}
                        onChange={handleChange}
                        label="Select Channel Name"
                        style={{ backgroundColor: 'white' }}
                      >
                        {forumMetadata?.forumMetadata?.channels?.map(
                          (chapter, index) => {
                            //Check if user is a chapter admin or not if the chapter value is Head of Chapters. In this case we do not want to show user this chapter in dropdown
                            const isHeadOfChapters = chapter === 'Head of Chapters';
                            const isAdmin = forumMetadata?.forumMetadata?.channel_admin_emails?.includes(tokenValue.preferred_username.toLowerCase());

                            // Render option conditionally
                            if (isHeadOfChapters && !isAdmin) return null;
                            return (
                              <MenuItem value={chapter} key={index}>
                                {chapter}
                              </MenuItem>
                            )
                          }
                        )}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
            </div>

            <button onClick={handleSubmit} className="Addbutton">
              Update question
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default EditPost
