import React from 'react'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Chip } from '@mui/material'
import { Button } from 'react-bootstrap'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
export default function templateStringValues(placeholderValues) {
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      {placeholderValues?.placeHolderValues?.length > 0 ? (
        <>
          {' '}
          <BootstrapTooltip title="Placeholder values can be used in template and survey creation. You can define placeholders in survey title, description and fields">
            <Button
              className="m_r-5 m_t-10 nav-btn btn-sm badge-nav-btn"
              onClick={handleClickOpen}
              style={{ backgroundColor: '#ff9d0b', borderColor: '#ff9d0b' }}
            >
              {' '}
              Placeholder
            </Button>
          </BootstrapTooltip>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Placeholder values
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={(theme) => ({
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                {' '}
                <Typography>
                  {' '}
                  You can use placeholders in survey title, survey description and
                  survey question labels to get value populated automatically as
                  per the below variables given for example YEAR variable will
                  return current year value, CHAPTER variable will return
                  current selected Chapter value and QUARTER variable will
                  return current quarter value.
                </Typography>
              </div>
              <div style={{ display: 'flex', marginBottom: '20px' }}>
                {placeholderValues?.placeHolderValues?.map((res, i) => {
                  return (
                    <div key={i} style={{ margin: '5px' }}>
                      <Chip label={res} color="primary" />
                    </div>
                  )
                })}
              </div>
              <Typography
                style={{ fontStyle: 'italic', color: 'red' }}
              >{`Note: To use variables wrap the values with {{ }} for example:- {{CHAPTER}}`}</Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Close
              </Button>
            </DialogActions>
          </BootstrapDialog>{' '}
        </>
      ) : (
        <></>
      )}
    </>
  )
}
