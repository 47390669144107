import React, { useContext, useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import jwt_decode from 'jwt-decode'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { RoleHandler } from '../Utilities/roleCheck'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import { useDispatch, useSelector } from 'react-redux'
import { getForumMetadata } from '../../../redux/actions/forumMetadataAction'
import { AuthContext } from '../../context/authProvider'
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
}))
export default function npsMenu({ setSort, sort, setStatusSort, statusSort }) {
  const [selectedIndexNPS, setSelectedIndexNPS] = React.useState(0)
  const [authCreds] = useContext(AuthContext)
  const [ischapterOwner, setIschapterOwner] = useState(false)
  const forumMetadata = useSelector((state) => state.forumMetadata)
  const dispatch = useDispatch()
  const endpoint = authCreds.restEndpoint
  let tokenValue = localStorage?.getItem('token')
  const value = jwt_decode(tokenValue)
  const email = value?.preferred_username?.toLowerCase()
  const isAdmin = RoleHandler.checkAdminRole(tokenValue)

  useEffect(() => {
    async function forumMetadata() {
      let url = endpoint + `/api/collab/get_forum_metadata?forum_type=DEFAULT`
      dispatch(getForumMetadata(url)).then(() => {})
    }
    forumMetadata()
  }, [])

  React.useEffect(() => {
    if (forumMetadata?.loading === false) {
      setIschapterOwner(
        forumMetadata?.forumMetadata?.channel_admin_emails?.includes(email)
      )
    }
  }, [forumMetadata])
  let history = useNavigate()

  React.useEffect(() => {
    switch (window.location.pathname) {
      case '/nps-survey/view-surveys':
        setSelectedIndexNPS(0)
        break
      case '/nps-survey/survey-admin':
        setSelectedIndexNPS(1)
        break
      case '/nps-survey/create-view-groups':
        setSelectedIndexNPS(2)
        break
      case '/nps-survey/create-chapter-survey':
        setSelectedIndexNPS(3)
        break
      case '/nps-survey/template':
        setSelectedIndexNPS(4)
        break
      case '/nps-survey/create-template':
        setSelectedIndexNPS(4)
        break
      case '/nps-survey/edit-template':
        setSelectedIndexNPS(4)
        break
      case '/nps-survey':
        setSelectedIndexNPS(3)
        break
      default:
        break
    }
  }, [])
  const handleListItemClick = (event, index) => {
    setSelectedIndexNPS(index)
    if (index === 0) {
      history('/nps-survey/view-surveys')
    } else if (index === 1) {
      history('/nps-survey/survey-admin')
    } else if (index === 2) {
      history('/nps-survey/create-view-groups')
    } else if (index === 3) {
      history('/nps-survey')
    } else if (index === 4) {
      history('/nps-survey/template')
    } else {
      history('/')
    }
  }

  const handleSort = (value) => {
    setSort(value)
  }
  const handleStatusSort = (value) => {
    setStatusSort(value)
  }
  return (
    <>
      <div className="nav-menu-nps">
        {ischapterOwner || isAdmin ? (
          <BootstrapTooltip
            title={
              'Create survey page will show you different templates including base and global template as per your selection of internal or external. You can customise your own survey by editing templates fields'
            }
          >
            <Button
              className={
                selectedIndexNPS == 3
                  ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                  : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
              }
              onClick={(event) => handleListItemClick(event, 3)}
            >
              Create Survey
            </Button>
          </BootstrapTooltip>
        ) : (
          <></>
        )}
        <BootstrapTooltip
          title={
            'Survey admin page will show you completion rate and nps score (if applicable). In this page admins can edit current survey, send reminders and see survey responses'
          }
        >
          <Button
            className={
              selectedIndexNPS == 1
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 1)}
          >
            Survey Admin
          </Button>
        </BootstrapTooltip>
        {ischapterOwner || isAdmin ? (
          <>
            <BootstrapTooltip
              title={
                'Survey group admin page will show you number of groups list where you can add or disable user directly'
              }
            >
              <Button
                className={
                  selectedIndexNPS == 2
                    ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                    : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
                }
                onClick={(event) => handleListItemClick(event, 2)}
              >
                Survey Group Admin
              </Button>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                'Click to see different templates including base and global template. You can customise your own template by editing base templates'
              }
            >
              <Button
                className={
                  selectedIndexNPS == 4
                    ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                    : 'm_r-5 m_t-10 nav-btn btn-sm badge-nav-btn'
                }
                onClick={(event) => handleListItemClick(event, 4)}
              >
                Create Template
              </Button>
            </BootstrapTooltip>
          </>
        ) : (
          <></>
        )}
        <BootstrapTooltip
          title={
            'Click to see all the list of surveys that has been assigned to you. You can see different card for each survey with their start and end date'
          }
        >
          <Button
            className={
              selectedIndexNPS == 0
                ? 'm_r-5 m_t-10 nav-btn  btn-sm active-btn'
                : 'm_r-5 m_t-10 nav-btn  btn-sm badge-nav-btn'
            }
            onClick={(event) => handleListItemClick(event, 0)}
          >
            {' '}
            My Surveys
          </Button>
        </BootstrapTooltip>

        {selectedIndexNPS == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Sort By</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={sort}
                  label="badge-name"
                  onChange={(e) => handleSort(e.target.value)}
                >
                  {/* {badgeLevels?.map((res) => {
              return ( */}
                  <MenuItem key="test" value="asc">
                    Asc
                  </MenuItem>
                  <MenuItem key="test1" value="desc">
                    Desc
                  </MenuItem>
                  <MenuItem key="test2" value="latest">
                    Latest
                  </MenuItem>
                  {/* )
            })} */}
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}

        {selectedIndexNPS == 0 ? (
          <div style={{ marginLeft: '14px' }}>
            <Box sx={{ minWidth: 180 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>

                <Select
                  sx={{
                    width: '100%',
                    height: '37px',
                    marginTop: '6px',
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-standard"
                  value={statusSort}
                  label="badge-name"
                  onChange={(e) => handleStatusSort(e.target.value)}
                >
                  <MenuItem key="test1" value="Pending">
                    Pending
                  </MenuItem>
                  <MenuItem key="test1" value="Completed">
                    Complete
                  </MenuItem>
                  <MenuItem key="test1" value="Not filled">
                    Not filled
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}
