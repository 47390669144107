import './App.css'
import React from 'react'
import Dashboard from './components/Dashboard'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { SignInButton } from './components/Helper/SignInButton'
import SnowflakeCloneParam from './components/pages/SnowFlakeClone/SnowflakeCloneParam'
import IngestionIntake from './components/pages/Ingestion_Intake/IngestionIntake'
import SearchBar from './components/pages/Lineage/SearchBar'
import StackOverflow from './components/pages/Collab/components/StackOverflow/index'
import KholabDashboard from './components/pages/KholabDashboard/index'
import Announcements from './components/pages/Announcements/index'
import Badge from './components/pages/Badge/index'
import PendingRequest from './components/pages/Badge/pendingRequest'
import PodsPendingRequests from './components/pages/Pods/podsPendingRequests'
import MyBadges from './components/pages/Badge/mybadges'
import Pods from './components/pages/Pods/pods'
import AdminBadge from './components/pages/Badge/adminBadgeAdd'
import DBTGradingDashboard from './components/pages/DBTGrading'
// import Header from "./components/pages/Collab/components/Header";
// import ChapterNPS from './components/pages/ChapterNPS/index'
import ChapterSurvey from './components/pages/ChapterNPS/createChapterSurvey'
import ChapterTemplate from './components/pages/ChapterNPS/template'
import BaseTemplate from './components/pages/ChapterNPS/createTemplateChapterSurvey'
import ChapterGroups from './components/pages/ChapterNPS/survey-group/surveyGroupChapter'
import SelectSurvey from './components/pages/ChapterNPS/selectSurveytype'
import SurveyChapterAdmin from './components/pages/ChapterNPS/survey-admin/surveyAdminChapter'
import ViewSurveyChapter from './components/pages/ChapterNPS/user-form/showAllUserFormsChapter'
import AddQuestion from './components/pages/Collab/components/AddQuestion/index'
import ViewQuestion from './components/pages/Collab/components/ViewQuestion/index'
import Menu from './components/Menu'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
} from 'react-router-dom'
import { AuthProvider } from './components/context/authProvider'
import { RefDataProvider } from './components/context/refDataProvider'
import InternetFrame from './components/Helper/InternetFrame'
import DataDictionary from './components/pages/DataDictionary/DataDictionary'
import JiraDashboard from './components/pages/JiraTracker/jiraDashboard'
import OcrTable from './components/pages/OCR_PDF/ocr_table'
import EditQuestion from './components/pages/Collab/components/EditQuestion/index'
import BQDataDictionary from './components/pages/DataDictionary_BQ/BQDataDictionary'
import ComingSoon from './components/pages/Collab/components/comingSoon'
import Home from './components/pages/Home'
import Steps from './components/pages/FnF/Steps/Steps'
import GridRules from './components/pages/FnF/GridRules/GridRules'
import Rule from './components/pages/FnF/Rule/Rule'
import FnFHeader from './components/pages/FnF/FnFHeader/FnFHeader'
import DbtReport from './components/pages/Utilities/dbtReport'
import Users from './components/pages/Users/users'
import AppHome from './components/pages/AppBar/AppHome'
import NPS from './components/pages/NPS/index'
import CreateForm from './components/pages/NPS/createForm'
import ShowAllForm from './components/pages/NPS/user-form/showAllUserForms'
import ShowForm from './components/pages/NPS/user-form/showUserForm'
import SurveyGroup from './components/pages/NPS/survey-group/surveyGroup'
import SurveyAdmin from './components/pages/NPS/survey-admin/surveyAdmin'
import AdminFormView from './components/pages/NPS/survey-admin/adminViewForm'
import Glossary from './components/pages/Glossary/index'
import GlossaryViewTerm from './components/pages/Glossary/ViewTerms/index'
import GlossaryPendingTerms from './components/pages/Glossary/PendingTerms/index'
import GlossaryApproveTerm from './components/pages/Glossary/ApproveTerm/index'
import IngestionIntakeTable from './components/pages/Ingestion_Intake/IngestionIntakeTable'
import IdeasDashboard from './components/pages/Ideas/index'
import ManageIdeas from './components/pages/Ideas/ManageIdeas'
import ViewIdeaDetail from './components/pages/Ideas/ViewIdea'
import JobPostings from './components/pages/JobPostings/index'
import SnfClusterDataTable from './components/pages/Snf_cluster/snfData'
import EditTemplate from './components/pages/ChapterNPS/editChapterSurveyTemplate'
export default function App() {
  const khde_url = 'https://khazeus2datastrgdev001.z20.web.core.windows.net/'
  const tableau_url =
    'https://analytics.kraftheinz.com/#/site/ITAnalytics/views/DE2_0ControlTower/DE2_0ControlTower_1?:iid=1'
  return (
    <React.Fragment>
      <AuthProvider>
        <Router>
          <Menu></Menu>
          <AuthenticatedTemplate>
            <div className="body-container">
              <Switch>
                <Route
                  path="/ref_data"
                  element={
                    <RefDataProvider>
                      <Dashboard />
                    </RefDataProvider>
                  }
                />
                <Route
                  path="/ref_data/:tablename"
                  element={
                    <RefDataProvider>
                      <Dashboard />
                    </RefDataProvider>
                  }
                  component={Dashboard}
                />
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route
                  path="/steps"
                  element={<FnFHeader component={<Steps />} />}
                />
                <Route
                  path="/steps/:jumpToStep"
                  element={<FnFHeader component={<Steps />} />}
                />
                <Route
                  path="/grid"
                  element={<FnFHeader component={<GridRules />} />}
                />
                <Route
                  path="/grid/:retailer"
                  element={<FnFHeader component={<GridRules />} />}
                />
                <Route
                  path="/gridRule/:ruleId"
                  element={<FnFHeader component={<Rule />} />}
                />

                <Route
                  path="/control-tower"
                  element={
                    <tableau-viz
                      id="tableauViz"
                      src={tableau_url}
                    ></tableau-viz>
                  }
                />
                <Route
                  path="/khde-docs"
                  element={<InternetFrame source_url={khde_url} />}
                />
                <Route
                  path="/snowflake_clone"
                  element={
                    <RefDataProvider>
                      <SnowflakeCloneParam />
                    </RefDataProvider>
                  }
                />
                <Route
                  path="/neo4j_lineage"
                  element={
                    <>
                      <SearchBar />
                    </>
                  }
                />
                <Route
                  path="/capacity_tracker"
                  element={
                    <>
                      <JiraDashboard />
                    </>
                  }
                />
                <Route path="/dbt-grading" element={<DBTGradingDashboard />} />
                <Route
                  path="/azure_ocr"
                  element={
                    <RefDataProvider>
                      <OcrTable />
                    </RefDataProvider>
                  }
                />
                <Route path="/data-dictionary/*" element={<DataDictionary />} />

                <Route path="/app-home" element={<AppHome />} />

                <Route
                  path="/data-dictionary-bq/*"
                  element={<BQDataDictionary />}
                />
              </Switch>
            </div>
            {/*  for full width pages routes. */}
            <Switch>
              <Route path="/dbt-report" element={<DbtReport />} />
              <Route path="/forum" element={<StackOverflow />} />
              <Route path="/kholab_home" element={<KholabDashboard/>} />
              <Route path="/Announcements" element={<Announcements />} />
              <Route path="/add-question" element={<AddQuestion />} />
              <Route path="/forum/post" element={<ViewQuestion />} />
              <Route path="/forum/edit-post" element={<EditQuestion />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/badge" element={<Badge />} />
              <Route path="/ideas" element={<IdeasDashboard />} />
              <Route path="/ideas/manage-ideas" element={<ManageIdeas />} />
              <Route path="/ideas/detail" element={<ViewIdeaDetail />} />
              <Route path="/jobs" element={<JobPostings />} />
              <Route
                path="/badge/pending-request"
                element={<PendingRequest />}
              />
              <Route
                path="/pods/pending-request"
                element={<PodsPendingRequests />}
              />
              <Route path="/badge/my-badges" element={<MyBadges />} />
              <Route path="/pods" element={<Pods />} />
              <Route path="/badge/admin-badge" element={<AdminBadge />} />
              <Route path="/users" element={<Users />} />
              <Route path="/nps" element={<NPS />} />
              <Route path="/create-survey" element={<CreateForm />} />
              <Route path="/view-surveys" element={<ShowAllForm />} />
              <Route path="/survey/:id" element={<ShowForm />} />
              <Route path="/create-view-groups" element={<SurveyGroup />} />
              <Route path="/survey-admin" element={<SurveyAdmin />} />
              <Route path="/admin-survey-view" element={<AdminFormView />} />
              <Route path="/glossary" element={<Glossary />} />
              <Route path="/glossary/term/:id" element={<GlossaryViewTerm />} />
              <Route
                path="/glossary/pending"
                element={<GlossaryPendingTerms />}
              />
              <Route
                path='/snf_cluster'
                element={<SnfClusterDataTable />}
                />
              <Route
                path="/glossary/approve-term/:id"
                element={<GlossaryApproveTerm />}
              />
              <Route
                path="/ingestion-intake"
                element={<IngestionIntakeTable />}
              />
              <Route
                path="/ingestion-intake/form"
                element={<IngestionIntake />}
              />
              {/* <Route
              path="/nps-survey" 
              element={<ChapterNPS />}
              /> */}
                <Route
              path="/nps-survey/create-chapter-survey" 
              element={<ChapterSurvey />}
              />
                 <Route
              path="/nps-survey/template" 
              element={<ChapterTemplate />}
              />
                 <Route
              path="/nps-survey/create-template" 
              element={<BaseTemplate />}
              />
                 <Route
              path="/nps-survey/edit-template" 
              element={<EditTemplate />}
              />
                <Route
              path="/nps-survey/create-view-groups" 
              element={<ChapterGroups />}
              />
               <Route
              path="/nps-survey" 
              element={<SelectSurvey />}
              />
                <Route
              path="/nps-survey/survey-admin" 
              element={<SurveyChapterAdmin />}
              />
                <Route
              path="/nps-survey/view-surveys" 
              element={<ViewSurveyChapter />}
              />

            </Switch>
          </AuthenticatedTemplate>
        </Router>
      </AuthProvider>
      <UnauthenticatedTemplate>
        <div className="login-button-container">
          <p className="sign-in-msg">You are not signed in! Please sign in.</p>
          <SignInButton />
        </div>
      </UnauthenticatedTemplate>
    </React.Fragment>
  )
}
