import {
  ADD_SURVEY_LOAD,
  ADD_SURVEY_POST,
  ADD_SURVEY_ERROR,
  ADD_SURVEY_GROUP_ERROR,
  ADD_SURVEY_GROUP_LOAD,
  ADD_SURVEY_GROUP_POST,
  GET_ADMIN_SURVEY_ERROR,
  GET_ADMIN_SURVEY_LOAD,
  GET_ADMIN_SURVEY_POST,
  ADD_USER_SURVEY_GROUP_ERROR,
  ADD_USER_SURVEY_GROUP_LOAD,
  ADD_USER_SURVEY_GROUP_POST,
  GET_SURVEY_GROUP_ERROR,
  GET_SURVEY_GROUP_LOAD,
  GET_SURVEY_GROUP_POST,
  GET_SURVEY_GROUP_USERS_LOAD,
  GET_SURVEY_GROUP_USERS_ERROR,
  GET_SURVEY_GROUP_USERS_POST,
  GET_SURVEY_ERROR,
  GET_SURVEY_LOAD,
  GET_SURVEY_POST,
  ACTIVATE_USER_SURVEY_GROUP_ERROR,
  ACTIVATE_USER_SURVEY_GROUP_LOAD,
  ACTIVATE_USER_SURVEY_GROUP_POST,
  GET_USER_SURVEY_LOAD,
  GET_USER_SURVEY_ERROR,
  GET_USER_SURVEY_POST,
  SUBMIT_SURVEY_LOAD,
  SUBMIT_SURVEY_POST,
  SUBMIT_SURVEY_ERROR,
  GET_SURVEY_GROUP_LIST_ERROR,
  GET_SURVEY_GROUP_LIST_LOAD,
  GET_SURVEY_GROUP_LIST_POST,
  EDIT_SURVEY_ERROR,
  EDIT_SURVEY_LOAD,
  EDIT_SURVEY_POST,
  GET_SURVEY_RESPONSE_ERROR,
  GET_SURVEY_RESPONSE_LOAD,
  GET_SURVEY_RESPONSE_POST,
  GET_SURVEY_RESPONSE_JSON_ERROR,
  GET_SURVEY_RESPONSE_JSON_LOAD,
  GET_SURVEY_RESPONSE_JSON_POST,
  SURVEY_REMINDER_LOAD,
  SURVEY_REMINDER_POST,
  SURVEY_REMINDER_ERROR,
  EXPORT_SURVEY_RESPONSE_LOAD,
  ADD_VALIDATE_USER_EMAILS_LOAD,
  ADD_VALIDATE_USER_EMAILS_POST,
  ADD_VALIDATE_USER_EMAILS_ERROR,

} from '../../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export const createSurvey = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/survey/create_survey`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_SURVEY_POST,
          payload: res.data,
        })
        toast('Survey Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const submitSurvey = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/survey/submit_survey_response`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: SUBMIT_SURVEY_POST,
          payload: res.data,
        })
        toast('Response Submitted', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: SUBMIT_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const createSurveyGroup = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_SURVEY_GROUP_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/survey/create_survey_group`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_SURVEY_GROUP_POST,
          payload: res.data,
        })
        toast('Survey Group Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_SURVEY_GROUP_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const addUserstoSurveyGroup =
  (endpoint, payload) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_USER_SURVEY_GROUP_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .post(`${endpoint}/api/survey/add_survey_group_members`, payload, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: ADD_USER_SURVEY_GROUP_POST,
            payload: res.data,
          })
          toast('Survey Group Added', toastTopRightSuccess)
        })
    } catch (e) {
      dispatch({
        type: ADD_USER_SURVEY_GROUP_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getAdminSurvey = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ADMIN_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_surveys_admin`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_ADMIN_SURVEY_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_ADMIN_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getSurveyGroup = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_GROUP_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_survey_groups`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SURVEY_GROUP_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_GROUP_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getSurveyGroupMembers = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_GROUP_USERS_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_survey_group_members`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SURVEY_GROUP_USERS_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_GROUP_USERS_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getSurvey = (endpoint, surveyId, isEdit) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(
        `${endpoint}/api/survey/view_survey?survey_id=${surveyId}&is_edit_view=${isEdit}`,
        {
          headers: post_headers,
        }
      )
      .then((res) => {
        dispatch({
          type: GET_SURVEY_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const enableDisableUserInGroup =
  (endpoint, value, params) => async (dispatch) => {
    try {
      dispatch({
        type: ACTIVATE_USER_SURVEY_GROUP_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .put(
          `${endpoint}/api/survey/enable_disable_survey_group_members?make_enable=${value}`,
          params,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: ACTIVATE_USER_SURVEY_GROUP_POST,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: ACTIVATE_USER_SURVEY_GROUP_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const getUserSurvey = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_my_surveys`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_USER_SURVEY_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_USER_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}
export const getSurveyGroupList = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_GROUP_LIST_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_survey_group_list`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SURVEY_GROUP_LIST_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_GROUP_LIST_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const editSurvey = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SURVEY_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/survey/edit_survey`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_SURVEY_POST,
          payload: res.data,
        })
        toast('Edit successfully', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: EDIT_SURVEY_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getSurveyResponse = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_RESPONSE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_survey_responses`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SURVEY_RESPONSE_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_RESPONSE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getSurveyResponseJSON = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SURVEY_RESPONSE_JSON_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/view_survey_response`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_SURVEY_RESPONSE_JSON_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_SURVEY_RESPONSE_JSON_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const sentSurveyReminder = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: SURVEY_REMINDER_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/survey/send_survey_reminder`, params, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: SURVEY_REMINDER_POST,
          payload: res.data,
        })
        if (res.data.message === 'Reminder sent sucessfully.') {
          toast('Survey Reminder Sent', toastTopRightSuccess)
        }
      })
  } catch (e) {
    dispatch({
      type: SURVEY_REMINDER_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const exportSurveyResponses =
  (endpoint, payload, surveyName) => async (dispatch) => {
    try {
      dispatch({
        type: EXPORT_SURVEY_RESPONSE_LOAD,
      })
      let post_headers = {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Disposition': `attachment; filename=${surveyName}_${payload.survey_id}.xlsx`,
        'Content-Type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      }
      await axios
        .post(`${endpoint}/api/survey/export_survey_responses`, payload, {
          headers: post_headers,
          responseType: 'blob',
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `${surveyName}_${payload.survey_id}.xlsx`
          )
          document.body.appendChild(link)
          link.click()
          toast('Survey Responses Exported Sucessfully.', toastTopRightSuccess)
        })
    } catch (e) {
      toast(e, toastTopRightError)
    }
  }

  export const getValidUsers = (endpoint, payload) => async (dispatch) => {
    try {
      dispatch({
        type: ADD_VALIDATE_USER_EMAILS_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }
  
      await axios
        .post(`${endpoint}/api/survey/validate_list_of_user_emails`, payload, {
          headers: post_headers,
        })
        .then((res) => {
          dispatch({
            type: ADD_VALIDATE_USER_EMAILS_POST,
            payload: res.data,
          })
          toast('User Emails Validated', toastTopRightSuccess)
        })
    } catch (e) {
      dispatch({
        type: ADD_VALIDATE_USER_EMAILS_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }
