import React, { useState, useEffect, useContext } from 'react'
import '../index.css'
import NavbarRow from '../../../Navbar'
import kh_logo from '../../../../media/kraftheinzlogo.png'
import HelpContent from '../helpContent'
import parse from 'html-react-parser'

import { useNavigate, useParams } from 'react-router-dom'
import { Box, Slider } from '@mui/material'
import {
  getSurvey,
  submitSurvey,
} from '../../../../redux/actions/npsActions/npsSurveyAction'
import { useDispatch, useSelector } from 'react-redux'
import { AuthContext } from '../../../context/authProvider'
import LoadingStatus from '../../../Helper/LoadingStatus'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Rating from '@mui/material/Rating'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}))

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied',
  },
}

function IconContainer(props) {
  const { value, ...other } = props
  return <span {...other}>{customIcons[value].icon}</span>
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
}
export default function ShowForm() {
  const [formValues, setFormValues] = useState([])
  const [checkVal, setcheckValue] = useState([])
  const [errors, setErrors] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [feedback, setFeedback] = useState(false)
  const [authCreds] = useContext(AuthContext)
  const endpoint = authCreds.restEndpoint
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const getSurveyData = useSelector((state) => state.getSurveyData)

  const [loadingStatus, setLoadingStatus] = useState({
    status: false,
    message: '',
  })
  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
  ]
  function valuetext(value) {
    return `${value}`
  }
  const handleFeedback = () => {
    setFeedback(!feedback)
  }
  const validateForm = (inputValues) => {
    let errors = {}
    errors = inputValues.map((obj) => {
      if (obj.required == true && obj.value == '' && feedback == false) {
        toast.error('Please Update Mandatory Fields')
        return (errors.error = 'This field is required')
      } else {
        return errors
      }
    })
    let newOption = errors.filter(function (element) {
      return element !== undefined
    })
    let newOption1 = [...new Set(newOption)]
    return newOption1
  }
  const handleChange = (e, index) => {
    // if(e.target.required == true && e.target.value == ''){
    //   setIsValidate(false)
    // }
    const { value, checked, type } = e.target
    if (checked && type == 'checkbox') {
      setcheckValue([...checkVal, value])
      const values = [...formValues]
      values[index].checkboxValue = [...checkVal, value]
      setFormValues(values)
    }
    const values = [...formValues]
    values[index].value = e.target.value
    setFormValues(values)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setErrors(validateForm(formValues))

    setSubmitting(true)
  }
  useEffect(() => {
    if (submitting == true) {
      if (Object.keys(errors).length === 0 || feedback == true) {
        // Submit the form
        setLoadingStatus({ status: true, message: 'Fetching table records...' })

        const params = {
          survey_id: id,
          survey_response: [],
          decline_feedback: feedback,
        }
        dispatch(submitSurvey(endpoint, params)).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
          navigate(-1)
        })
      } else if (Object.keys(errors).length === 0 || feedback == false) {
        // Submit the form
        setLoadingStatus({ status: true, message: 'Fetching table records...' })

        const params = {
          survey_id: id,
          survey_response: formValues,
          decline_feedback: feedback,
        }
        dispatch(submitSurvey(endpoint, params)).then(() => {
          setLoadingStatus({
            status: false,
            message: 'Fetching table records...',
          })
          navigate(-1)
        })
      }
    }
  }, [errors])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    if (getSurveyData?.loading == false) {
      setFormValues(getSurveyData?.getSurveyData?.data?.survey_json)
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    }
  }, [getSurveyData])

  useEffect(() => {
    setLoadingStatus({ status: true, message: 'Fetching table records...' })

    dispatch(getSurvey(endpoint, id, 'false')).then(() => {
      setLoadingStatus({ status: false, message: 'Fetching table records...' })
    })
  }, [])
  return (
    <>
      {loadingStatus.status ? (
        <LoadingStatus status_message={loadingStatus.message} />
      ) : (
        <div className="nps">
          <NavbarRow title="NPS" HelpContent={HelpContent} />
          <button onClick={() => navigate(-1)} class="button-2">
            Go back
          </button>
          <div className="main-view-form">
            <div className="view-form">
              <div className="user-form-heading">
                <div>
                  <h2>{getSurveyData?.getSurveyData?.data?.form_name}</h2>
                  <p>{getSurveyData?.getSurveyData?.data?.description}</p>
                </div>
                <img
                  className="logo-nps"
                  src={kh_logo}
                  alt="Kraft heinz logo"
                />{' '}
              </div>
              {formValues?.map((obj, index) => {
                return (
                  <div className="input-group-nps" key={index}>
                    <div>
                      {obj.type == 'text-read-only' ? (
                        <></>
                      ) : (
                        <label htmlFor={obj.label}>{obj.label}</label>
                      )}
                      {obj.required == true ? (
                        <label htmlFor={obj.label} style={{ color: 'red' }}>
                          *
                        </label>
                      ) : (
                        <></>
                      )}
                    </div>
                    {obj.type == 'radio' ? (
                      <div className="input-nps">
                        {obj.options?.map((option, i) => {
                          return (
                            <div key={i} className="radio-input-nps">
                              <input
                                type="radio"
                                id={option}
                                value={option}
                                name="option"
                                onChange={(e) => handleChange(e, index)}
                                required={obj.required ? true : false}
                              />
                              <label
                                className="radio-label-nps"
                                htmlFor={option}
                              >
                                {option}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    ) : obj.type == 'checkbox' ? (
                      <div className="input-nps">
                        {obj.options?.map((option, i) => {
                          return (
                            <div key={i} className="radio-input-nps">
                              <input
                                type="checkbox"
                                id={option}
                                value={option}
                                required={obj.required ? true : false}
                                onChange={(e) => handleChange(e, index)}
                              />
                              <label
                                className="radio-label-nps"
                                htmlFor={option}
                              >
                                {option}
                              </label>
                            </div>
                          )
                        })}
                      </div>
                    ) : obj.type == 'text-read-only' ? (
                      <div className="input-nps">
                        <p> {parse(obj.label)}</p>
                      </div>
                    ) : obj.type == 'textarea' ? (
                      <div className="input-nps">
                        <textarea
                          id={obj.label}
                          placeholder={obj.placeholder || ''}
                          name={obj.label}
                          onChange={(e) => handleChange(e, index)}
                          rows="4"
                          cols="70"
                        />
                      </div>
                    ) : obj.type == 'Nps' ? (
                      <Box>
                        <Slider
                          aria-label="nps"
                          getAriaValueText={valuetext}
                          onChange={(e) => handleChange(e, index)}
                          valueLabelDisplay="auto"
                          step={1}
                          marks={marks}
                          min={0}
                          max={10}
                        />
                      </Box>
                    ) : obj.type == 'rating' ? (
                      <div>
                        {obj.ratingType == 'star' ? (
                          <>
                            <Rating
                              name="half-rating"
                              onChange={(e) => handleChange(e, index)}
                              precision={1}
                            />
                          </>
                        ) : obj.ratingType == 'Heart' ? (
                          <>
                            <StyledRating
                              name="customized-color"
                              onChange={(e) => handleChange(e, index)}
                              getLabelText={(value) =>
                                `${value} Heart${value !== 1 ? 's' : ''}`
                              }
                              precision={1}
                              style={{ color: 'red' }}
                              icon={<FavoriteIcon fontSize="inherit" />}
                              emptyIcon={
                                <FavoriteBorderIcon fontSize="inherit" />
                              }
                            />
                          </>
                        ) : obj.ratingType == 'faces' ? (
                          <>
                            <StyledRating
                              name="highlight-selected-only"
                              IconContainerComponent={IconContainer}
                              getLabelText={(value) => customIcons[value].label}
                              highlightSelectedOnly
                              onChange={(e) => handleChange(e, index)}
                              s
                            />
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <>
                        {' '}
                        <div className="input-nps">
                          <input
                            type={obj.type || 'text'}
                            id={obj.label}
                            value={obj.value || ''}
                            placeholder={obj.placeholder || ''}
                            required={obj.required ? true : false}
                            onChange={(e) => handleChange(e, index)}
                          />
                        </div>
                      </>
                    )}
                  </div>
                )
              })}
              <div style={{ display: 'flex', margin: '10px' }}>
                <input
                  type="checkbox"
                  checked={feedback}
                  onChange={handleFeedback}
                />
                <p style={{ marginLeft: '10px' }}>
                  I do not wish to provide feedback
                </p>
              </div>
              <div className="center-view-form-btn">
                <button className="add-btn-nps" onClick={handleSubmit}>
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
