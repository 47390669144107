import {
  ADD_TEMPLATE_LOAD,
  ADD_TEMPLATE_POST,
  ADD_TEMPLATE_ERROR,
  GET_TEMPLATE_LIST_LOAD,
  GET_TEMPLATE_LIST_POST,
  GET_TEMPLATE_LIST_ERROR,
  GET_TEMPLATE_LOAD,
  GET_TEMPLATE_POST,
  GET_TEMPLATE_ERROR,
  DELETE_TEMPLATE_LOAD,
  DELETE_TEMPLATE_POST,
  DELETE_TEMPLATE_ERROR,
  EDIT_SURVEY_TEMPLATE_LOAD,
  EDIT_SURVEY_TEMPLATE_POST,
  EDIT_SURVEY_TEMPLATE_ERROR,
  GET_TEMPLATE_PLACEHOLDER_LOAD,
  GET_TEMPLATE_PLACEHOLDER_POST,
  GET_TEMPLATE_PLACEHOLDER_ERROR,
} from '../../constants/types'
import axios from 'axios'
import {
  toastTopRightError,
  toastTopRightSuccess,
} from '../../../components/Helper/ToastObjects'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
export const createTemplate = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: ADD_TEMPLATE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .post(`${endpoint}/api/survey/create_survey_template`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: ADD_TEMPLATE_POST,
          payload: res.data,
        })
        toast('Survey Added', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: ADD_TEMPLATE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getSurveyTemplateList = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_TEMPLATE_LIST_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .get(`${endpoint}/api/survey/get_survey_templates`, {
        params: params,
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: GET_TEMPLATE_LIST_POST,
          payload: res.data,
        })
      })
  } catch (e) {
    dispatch({
      type: GET_TEMPLATE_LIST_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getSurveyTemplate =
  (endpoint, template_name) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TEMPLATE_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .get(
          `${endpoint}/api/survey/view_survey_template?template_name=${template_name}`,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: GET_TEMPLATE_POST,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_TEMPLATE_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }

export const deleteTemplate = (endpoint, payload) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_TEMPLATE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/survey/delete_survey_template`, payload, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: DELETE_TEMPLATE_POST,
          payload: res.data,
        })
        toast('Record Deleted', toastTopRightSuccess)
      })
  } catch (e) {
    dispatch({
      type: DELETE_TEMPLATE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const editSurveyTemplate = (endpoint, params) => async (dispatch) => {
  try {
    dispatch({
      type: EDIT_SURVEY_TEMPLATE_LOAD,
    })
    let post_headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      khuser: `${localStorage.getItem('user')}`,
    }

    await axios
      .put(`${endpoint}/api/survey/edit_survey_template`, params, {
        headers: post_headers,
      })
      .then((res) => {
        dispatch({
          type: EDIT_SURVEY_TEMPLATE_POST,
          payload: res.data,
        })
        toast('Edit Successfully', toastTopRightSuccess)

      })
  } catch (e) {
    dispatch({
      type: EDIT_SURVEY_TEMPLATE_ERROR,
      payload: e.response.data.message,
    })
    toast(e.response.data.message, toastTopRightError)
  }
}

export const getTemplatePlaceholder =
  (endpoint) => async (dispatch) => {
    try {
      dispatch({
        type: GET_TEMPLATE_PLACEHOLDER_LOAD,
      })
      let post_headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        khuser: `${localStorage.getItem('user')}`,
      }

      await axios
        .get(
          `${endpoint}/api/survey/get_template_placeholders`,
          {
            headers: post_headers,
          }
        )
        .then((res) => {
          dispatch({
            type: GET_TEMPLATE_PLACEHOLDER_POST,
            payload: res.data,
          })
        })
    } catch (e) {
      dispatch({
        type: GET_TEMPLATE_PLACEHOLDER_ERROR,
        payload: e.response.data.message,
      })
      toast(e.response.data.message, toastTopRightError)
    }
  }
